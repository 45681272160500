// https://global.espn.com/mlb/teams
// https://www.mlb.com/team

const AmericanTeams = [
  {
    name: 'Chicago White Sox',
    data: '/images/mlb/logos/CWS.png',
    division: 'American Central',
  },
  {
    name: 'Cleveland Guardians',
    data: '/images/mlb/logos/CLE.png',
    division: 'American Central',
  },
  {
    name: 'Detroit Tigers',
    data: '/images/mlb/logos/DET.png',
    division: 'American Central',
  },
  {
    name: 'Kansas City Royals',
    data: '/images/mlb/logos/KC.png',
    division: 'American Central',
  },
  {
    name: 'Minnesota Twins',
    data: '/images/mlb/logos/MIN.png',
    division: 'American Central',
  },
  {
    name: 'Baltimore Orioles',
    data: '/images/mlb/logos/BAL.png',
    division: 'American East',
  },
  {
    name: 'Boston Red Sox',
    data: '/images/mlb/logos/BOS.png',
    division: 'American East',
  },
  {
    name: 'New York Yankees',
    data: '/images/mlb/logos/NYY.png',
    division: 'American East',
  },
  {
    name: 'Tampa Bay Rays',
    data: '/images/mlb/logos/TB.png',
    division: 'American East',
  },
  {
    name: 'Toronto Blue Jays',
    data: '/images/mlb/logos/TOR.png',
    division: 'American East',
  },
  {
    name: 'Oakland Athletics',
    data: '/images/mlb/logos/OAK.png',
    division: 'American West',
  },
  {
    name: 'Houston Astros',
    data: '/images/mlb/logos/HOU.png',
    division: 'American West',
  },
  {
    name: 'Los Angeles Angels',
    data: '/images/mlb/logos/LAA.png',
    division: 'American West',
  },
  {
    name: 'Seattle Mariners',
    data: '/images/mlb/logos/SEA.png',
    division: 'American West',
  },
  {
    name: 'Texas Rangers',
    data: '/images/mlb/logos/TEX.png',
    division: 'American West',
  },
]

const NationalTeams = [
  {
    name: 'Chicago Cubs',
    data: '/images/mlb/logos/CHC.png',
    division: 'National Central',
  },
  {
    name: 'Cincinnati Reds',
    data: '/images/mlb/logos/CIN.png',
    division: 'National Central',
  },
  {
    name: 'Milwaukee Brewers',
    data: '/images/mlb/logos/MIL.png',
    division: 'National Central',
  },
  {
    name: 'Pittsburgh Pirates',
    data: '/images/mlb/logos/PIT.png',
    division: 'National Central',
  },
  {
    name: 'St. Louis Cardinals',
    data: '/images/mlb/logos/STL.png',
    division: 'National Central',
  },
  {
    name: 'Atlanta Braves',
    data: '/images/mlb/logos/ATL.png',
    division: 'National East',
  },
  {
    name: 'Miami Marlins',
    data: '/images/mlb/logos/MIA.png',
    division: 'National East',
  },
  {
    name: 'New York Mets',
    data: '/images/mlb/logos/NYM.png',
    division: 'National East',
  },
  {
    name: 'Philadelphia Phillies',
    data: '/images/mlb/logos/PHI.png',
    division: 'National East',
  },
  {
    name: 'Washington Nationals',
    data: '/images/mlb/logos/WSH.png',
    division: 'National East',
  },
  {
    name: 'Arizona Diamondbacks',
    data: '/images/mlb/logos/ARI.png',
    division: 'National West',
  },
  {
    name: 'Colorado Rockies',
    data: '/images/mlb/logos/COL.png',
    division: 'National West',
  },
  {
    name: 'Los Angeles Dodgers',
    data: '/images/mlb/logos/LAD.png',
    division: 'National West',
  },
  {
    name: 'San Diego Padres',
    data: '/images/mlb/logos/SD.png',
    division: 'National West',
  },
  {
    name: 'San Francisco Giants',
    data: '/images/mlb/logos/SF.png',
    division: 'National West',
  },
]

export const allTeams = [...AmericanTeams, ...NationalTeams]

export const ConferenceList = {
  AllTeams: {
    name: 'All Teams',
    dataList: allTeams,
    divisionList: [
      'American Central',
      'American East',
      'American West',
      'National Central',
      'National East',
      'National West',
    ],
  },
  American: {
    name: 'American',
    dataList: AmericanTeams,
    divisionList: ['American Central', 'American East', 'American West'],
  },
  National: {
    name: 'National',
    dataList: NationalTeams,
    divisionList: ['National Central', 'National East', 'National West'],
  },
}
