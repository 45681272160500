// https://global.espn.com/nba/teams
// https://www.nba.com/standings?GroupBy=conf

const EasternTeams = [
  {
    name: 'Cleveland Cavaliers',
    data: '/images/nba/logos/CLE.png',
    division: 'Eastern Central',
  },
  {
    name: 'Boston Celtics',
    data: '/images/nba/logos/BOS.png',
    division: 'Eastern Atlantic',
  },
  {
    name: 'New York Knicks',
    data: '/images/nba/logos/NYK.png',
    division: 'Eastern Atlantic',
  },
  {
    name: 'Orlando Magic',
    data: '/images/nba/logos/ORL.png',
    division: 'Eastern Southeast',
  },
  {
    name: 'Milwaukee Bucks',
    data: '/images/nba/logos/MIL.png',
    division: 'Eastern Central',
  },
  {
    name: 'Miami Heat',
    data: '/images/nba/logos/MIA.png',
    division: 'Eastern Southeast',
  },
  {
    name: 'Atlanta Hawks',
    data: '/images/nba/logos/ATL.png',
    division: 'Eastern Southeast',
  },
  {
    name: 'Indiana Pacers',
    data: '/images/nba/logos/IND.png',
    division: 'Eastern Central',
  },
  {
    name: 'Chicago Bulls',
    data: '/images/nba/logos/CHI.png',
    division: 'Eastern Central',
  },
  {
    name: 'Detroit Pistons',
    data: '/images/nba/logos/DET.png',
    division: 'Eastern Central',
  },
  {
    name: 'Brooklyn Nets',
    data: '/images/nba/logos/BKN.png',
    division: 'Eastern Atlantic',
  },
  {
    name: 'Philadelphia 76ers',
    data: '/images/nba/logos/PHI.png',
    division: 'Eastern Atlantic',
  },
  {
    name: 'Charlotte Hornets',
    data: '/images/nba/logos/CHA.png',
    division: 'Eastern Southeast',
  },
  {
    name: 'Toronto Raptors',
    data: '/images/nba/logos/TOR.png',
    division: 'Eastern Atlantic',
  },
  {
    name: 'Washington Wizards',
    data: '/images/nba/logos/WAS.png',
    division: 'Eastern Southeast',
  },
]

const WesternTeams = [
  {
    name: 'Oklahoma City Thunder',
    data: '/images/nba/logos/OKC.png',
    division: 'Western Northwest',
  },
  {
    name: 'Houston Rockets',
    data: '/images/nba/logos/HOU.png',
    division: 'Western Southwest',
  },
  {
    name: 'Memphis Grizzlies',
    data: '/images/nba/logos/MEM.png',
    division: 'Western Southwest',
  },
  {
    name: 'Dallas Mavericks',
    data: '/images/nba/logos/DAL.png',
    division: 'Western Southwest',
  },
  {
    name: 'Denver Nuggets',
    data: '/images/nba/logos/DEN.png',
    division: 'Western Northwest',
  },
  {
    name: 'LA Clippers',
    data: '/images/nba/logos/LAC.png',
    division: 'Western Pacific',
  },
  {
    name: 'Los Angeles Lakers',
    data: '/images/nba/logos/LAL.png',
    division: 'Western Pacific',
  },
  {
    name: 'Golden State Warriors',
    data: '/images/nba/logos/GSW.png',
    division: 'Western Pacific',
  },
  {
    name: 'Minnesota Timberwolves',
    data: '/images/nba/logos/MIN.png',
    division: 'Western Northwest',
  },
  {
    name: 'Phoenix Suns',
    data: '/images/nba/logos/PHX.png',
    division: 'Western Pacific',
  },
  {
    name: 'San Antonio Spurs',
    data: '/images/nba/logos/SAS.png',
    division: 'Western Southwest',
  },
  {
    name: 'Sacramento Kings',
    data: '/images/nba/logos/SAC.png',
    division: 'Western Pacific',
  },
  {
    name: 'Portland Trail Blazers',
    data: '/images/nba/logos/POR.png',
    division: 'Western Northwest',
  },
  {
    name: 'Utah Jazz',
    data: '/images/nba/logos/UTA.png',
    division: 'Western Northwest',
  },
  {
    name: 'New Orleans Pelicans',
    data: '/images/nba/logos/NOP.png',
    division: 'Western Southwest',
  },
]

export const allTeams = [...EasternTeams, ...WesternTeams]

export const ConferenceList = {
  AllTeams: {
    name: 'All Teams',
    dataList: allTeams,
    divisionList: [
      'Eastern Atlantic',
      'Eastern Central',
      'Eastern Southeast',
      'Western Northwest',
      'Western Pacific',
      'Western Southwest',
    ],
  },
  Eastern: {
    name: 'Eastern',
    dataList: EasternTeams,
    divisionList: ['Eastern Atlantic', 'Eastern Central', 'Eastern Southeast'],
  },
  Western: {
    name: 'Western',
    dataList: WesternTeams,
    divisionList: ['Western Northwest', 'Western Pacific', 'Western Southwest'],
  },
}
