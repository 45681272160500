export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_MLB'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_MLB'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_MLB'
export const SET_MODE = 'SET_MODE_MLB'
export const SET_CHOICES = 'SET_CHOICES_MLB'
export const SET_HISTORY = 'SET_HISTORY_MLB'
export const SET_CONFERENCE_ACTIVE = 'SET_CONFERENCE_ACTIVE_MLB'
export const SET_SHOW_MODE = 'SET_SHOW_MODE_MLB'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_MLB'
export const INSTANT_INIT = 'INSTANT_INIT_MLB'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_MLB'
export const REPLACE_STATE = 'REPLACE_STATE_MLB'
export const SET_TEAMS_ACTIVE = 'SET_TEAMS_ACTIVE_MLB'
