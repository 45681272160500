export const SHOW_TURNTABLE = 'SHOW_TURNTABLE_RSG'
export const SET_TURNTABLE_SIZE = 'SET_TURNTABLE_SIZE_RSG'
export const SET_RESULT_SELECTED = 'SET_RESULT_SELECTED_RSG'
export const SET_MODE = 'SET_MODE_RSG'
export const SET_CHOICES = 'SET_CHOICES_RSG'
export const SET_HISTORY = 'SET_HISTORY_RSG'
export const SET_COUNTRY_ACTIVE = 'SET_COUNTRY_ACTIVE_RSG'
export const SET_SHOW_MODE = 'SET_SHOW_MODE_RSG'
export const SET_TITLE_OBJ = 'SET_TITLE_OBJ_RSG'
export const INSTANT_INIT = 'INSTANT_INIT_RSG'
export const RESET_INITIAL_STATE = 'RESET_INITIAL_STATE_RSG'
export const REPLACE_STATE = 'REPLACE_STATE_RSG'
export const SET_STATES_ACTIVE = 'SET_STATES_ACTIVE_RSG'
