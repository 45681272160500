import { httpsCallable } from 'firebase/functions'
import {
  KEEP_SHARE_LINK,
  SET_PREVIOUS_STATE,
  // SET_SHARE_CONFIG,
  UPDATE_CURRENT_CONFIG,
  SET_FILE_SHARE_CONFIG,
  SET_INITIALIZED,
} from './constants'
// import {
//   asyncActionStart,
//   asyncActionFinish,
//   asyncActionError,
// } from '../async/actions'
import equal from 'fast-deep-equal'
import {
  setChoices as setChoicesMain,
  setMode as setModeMain,
  changeKeyText as changeKeyTextMain,
  setTitleObj as setTitleObjMain,
  setWeightOn as setWeightOnMain,
  // setTitleActive as setTitleActiveMain,
} from '../homepage/actions'
import {
  setChoices as setChoicesRIG,
  setMode as setModeRIG,
  changeKeyText as changeKeyTextRIG,
  setTitleObj as setTitleObjRIG,
  // setTitleActive as setTitleActiveRIG,
} from '../rig/actions'
import {
  setPeople as setPeopleRTG,
  changeKeyTextRTG,
  setTitleObj as setTitleObjRTG,
  setEvenSplit as setEvenSplitRTG,
  setNumGroups as setNumGroupsRTG,
  setGroupNames as setGroupNamesRTG,
  setShowIcon as setShowIconRTG,
  setPickQuantity as setPickQuantityRTG,
  setRepresentativeIndexes as setRepresentativeIndexesRTG,
  setPickRepresentative as setPickRepresentativeRTG,
  setPresetGroups as setPresetGroupsRTG,
  // setTitleActive as setTitleActiveRTG,
} from '../rtg/actions'
import {
  setTitleObj as setTitleObjYNW,
  setInputMode as setInputModeYNW,
  setNumInputSet as setNumInputSetYNW,
  // setTitleActive as setTitleActiveYNW,
} from '../ynw/actions'
import {
  setTitleObj as setTitleObjRNG,
  setTypeOption as setTypeOptionRNG,
  setAutoSpinMerge as setAutoSpinMergeRNG,
  setMinValueStore as setMinValueRNG,
  setMaxValueStore as setMaxValueRNG,
  setIntervalValueStore as setIntervalValueRNG,
  setExcludeFieldValue as setExcludeFieldValueRNG,
  setInputMethod as setInputMethodRNG,
  setFormulaFieldValue as setFormulaFieldValueRNG,
  setDigitRanges as setDigitRangesRNG,
  setDigitNumber as setDigitNumberRNG,
  setResultsArray as setResultsArrayRNG,
  // setTitleActive as setTitleActiveRNG,
} from '../rng/actions'
import {
  setTitleObj as setTitleObjRLG,
  setInputModeStore as setInputModeRLG,
  setStyleModeStore as setStyleModeRLG,
  setCustomInputStore as setCustomInputRLG,
  // setTitleActive as setTitleActiveRLG,
} from '../rlg/actions'
import { updateAllSettings } from '../toolSettings/actions'
import getFirebase from '../../utils/firebase/firebase'
import {
  refactorSoundSettings,
  refactorSpinBehavior,
  refactorBgColorSettings,
  refactorEvenSplitRTG,
  refactorTitle,
} from '../../utils'
import cloneDeep from 'lodash.clonedeep'
import { functions, auth } from '../../utils/firebase/firebase'
import { setWebsiteMode } from '../common/actions'
import { ContinentActiveList } from '../../component/ReactRCG/data'
import {
  setTitleObj as setTitleObjRCG,
  setContinentsActive as setContinentsActiveRCG,
  setShowMode as setShowModeRCG,
  setChoices as setChoicesRCG,
} from '../rcg/actions'
import { isImageData } from '../../utils/imageUtil'
import {
  setTitleObj as setTitleObjRDG,
  setInputModeStore as setInputModeRDG,
  setDaysModeStore as setDaysModeRDG,
  setInputsStore as setInputsRDG,
} from '../rdg/actions'
import {
  setChoices as setChoicesRCW,
  changeKeyText as changeKeyTextRCW,
  setTitleObj as setTitleObjRCW,
  setShowMode as setShowModeRCW,
  setInputMethod as setInputMethodRCW,
  setMode as setModeRCW,
  updateAllColorWheelStates as updateAllColorWheelStatesRCW,
} from '../rcw/actions'
import {
  setTitleObj as setTitleObjNFL,
  setConferenceActive as setConferenceActiveNFL,
  setShowMode as setShowModeNFL,
  setChoices as setChoicesNFL,
  setTeamsActive as setTeamsActiveNFL,
} from '../nfl/actions'
import {
  setTitleObj as setTitleObjNBA,
  setConferenceActive as setConferenceActiveNBA,
  setShowMode as setShowModeNBA,
  setChoices as setChoicesNBA,
  setTeamsActive as setTeamsActiveNBA,
} from '../nba/actions'
import {
  setTitleObj as setTitleObjRSG,
  setCountryActive as setCountryActiveRSG,
  setShowMode as setShowModeRSG,
  setChoices as setChoicesRSG,
  setStatesActive as setStatesActiveRSG,
} from '../rsg/actions'
import {
  setTitleObj as setTitleObjMLB,
  setConferenceActive as setConferenceActiveMLB,
  setShowMode as setShowModeMLB,
  setChoices as setChoicesMLB,
  setTeamsActive as setTeamsActiveMLB,
} from '../mlb/actions'

import {
  turnOffPremiumSettings,
  instantInitSettings,
} from '../toolSettings/actions'

import { disableAndRemoveAds, setSharePremium } from '../auth/actions'

const choicesAction = {
  main: (choices, dispatch) => dispatch(setChoicesMain(choices)),
  rig: (choices, dispatch) => dispatch(setChoicesRIG(choices)),
  rcw: (choices, dispatch) => dispatch(setChoicesRCW(choices)),
  // rtg: (people, dispatch) => dispatch(setPeopleRTG(people)),
}

const modesAction = {
  main: (mode, dispatch) => dispatch(setModeMain(mode)),
  rig: (mode, dispatch) => dispatch(setModeRIG(mode)),
  rcw: (mode, dispatch) => dispatch(setModeRCW(mode)),
  // rtg: () => {},
}

const keyTextsAction = {
  main: dispatch => dispatch(changeKeyTextMain()),
  rig: dispatch => dispatch(changeKeyTextRIG()),
  rcw: dispatch => dispatch(changeKeyTextRCW()),
  // rtg: dispatch => dispatch(changeKeyTextRTG()),
}

const titleObjAction = {
  main: (obj, dispatch) => dispatch(setTitleObjMain(obj)),
  rig: (obj, dispatch) => dispatch(setTitleObjRIG(obj)),
  rtg: (obj, dispatch) => dispatch(setTitleObjRTG(obj)),
  ynw: (obj, dispatch) => dispatch(setTitleObjYNW(obj)),
  rng: (obj, dispatch) => dispatch(setTitleObjRNG(obj)),
  rlg: (obj, dispatch) => dispatch(setTitleObjRLG(obj)),
  rcg: (obj, dispatch) => dispatch(setTitleObjRCG(obj)),
  rdg: (obj, dispatch) => dispatch(setTitleObjRDG(obj)),
  rcw: (obj, dispatch) => dispatch(setTitleObjRCW(obj)),
  nfl: (obj, dispatch) => dispatch(setTitleObjNFL(obj)),
  nba: (obj, dispatch) => dispatch(setTitleObjNBA(obj)),
  rsg: (obj, dispatch) => dispatch(setTitleObjRSG(obj)),
  mlb: (obj, dispatch) => dispatch(setTitleObjMLB(obj)),
}

// const titleActiveAction = {
//   main: (status, dispatch) => dispatch(setTitleActiveMain(status)),
//   rig: (status, dispatch) => dispatch(setTitleActiveRIG(status)),
//   rtg: (status, dispatch) => dispatch(setTitleActiveRTG(status)),
//   ynw: (status, dispatch) => dispatch(setTitleActiveYNW(status)),
//   rng: (status, dispatch) => dispatch(setTitleActiveRNG(status)),
//   rlg: (status, dispatch) => dispatch(setTitleActiveRLG(status)),
// }

export const createShareLink = inputs => async (dispatch, getState) => {
  // dispatch(asyncActionStart('share'))
  // const { sharePage, inputChecked, resultChecked, settingsChecked } = inputs
  const {
    sharePage,
    titleChecked,
    settingsChecked,
    shareCanEdit,
    shareMethod,
    noAdsChecked,
    isPremium,
  } = inputs
  const state = getState()
  const { lastCreate, createdCount } = state.share
  const user = auth.currentUser
  const fSettings = state.settings
  const fileKey = state.common.currentFileKey

  let toolData = null
  let settingsData = null
  switch (sharePage) {
    case 'main':
      {
        // if (resultChecked) {     // dont support result checked
        //   toolData = {
        //     choices: JSON.parse(JSON.stringify([...fChoices])),
        //     mode: fMode,
        //   }
        // } else {
        const fChoices = state.main.choices
        const weightOn = state.main.weightOn
        // const tempChoices = JSON.parse(JSON.stringify([...fChoices]))
        const tempChoices = cloneDeep(fChoices)
        tempChoices.forEach(choice => {
          choice.count = 0
          choice.filtered = false
          if (!weightOn && choice.weight) {
            delete choice.weight
          }
        })
        toolData = {
          choices: tempChoices,
          mode: 0,
        }
        if (weightOn) {
          toolData['weightOn'] = weightOn
        }
        // }
      }
      break
    case 'rig':
      {
        // if (resultChecked) {     // dont support result checked
        //   toolData = {
        //     choices: JSON.parse(JSON.stringify([...fChoices])),
        //     mode: fMode,
        //   }
        // } else {
        const fChoices = state.rig.choices
        // const tempChoices = JSON.parse(JSON.stringify([...fChoices]))
        const tempChoices = cloneDeep(fChoices)
        tempChoices.forEach(choice => {
          choice.count = 0
          choice.filtered = false
        })
        toolData = {
          choices: tempChoices,
          mode: 0,
        }
        // }
      }
      break
    case 'rtg':
      {
        const fPeople = state.rtg.people
        const numGroups = state.rtg.numGroups
        const evenSplit = state.rtg.evenSplit
        const groupNames = state.rtg.groupNames
        const showIcon = state.rtg.showIcon
        const pickQuantity = state.rtg.pickQuantity
        toolData = {
          people: fPeople.slice(),
          numGroups,
          evenSplit,
          groupNames,
          showIcon,
          pickQuantity,
        }
      }
      break
    case 'ynw':
      {
        const inputMode = state.ynw.inputMode
        const numInputSet = state.ynw.numInputSet
        toolData = {
          inputMode,
          numInputSet,
        }
      }
      break
    case 'rlg':
      {
        const { inputMode, styleMode, customInput } = state.rlg
        toolData = {
          inputMode,
          styleMode,
          customInput,
        }
      }
      break
    case 'rcg':
      {
        const { choices, showMode, continentsActive } = state.rcg
        toolData = {
          choices,
          showMode,
          continentsActive,
        }
      }
      break
    case 'rng':
      {
        const {
          typeOption,
          autoSpinMerge,
          minValue,
          maxValue,
          intervalValue,
          excludeFieldValue,
          formulaFieldValue,
          inputMethod,
          digitRanges,
          digitNumber,
          resultsArray,
        } = state.rng
        if (typeOption === 0) {
          if (inputMethod === 'By Range') {
            //By range method
            toolData = {
              typeOption,
              minValue,
              maxValue,
              intervalValue,
              excludeFieldValue,
              inputMethod,
            }
          } else {
            toolData = {
              typeOption,
              inputMethod,
              formulaFieldValue,
            }
          }
        } else {
          const arr = Array.from(Array(resultsArray.length), () => null)
          toolData = {
            typeOption,
            autoSpinMerge,
            digitRanges: JSON.stringify(digitRanges),
            digitNumber,
            resultsArray: arr,
          }
        }
      }
      break
    case 'rdg':
      {
        const { inputMode, daysMode, inputs } = state.rdg
        toolData = {
          inputMode,
          daysMode,
          inputs,
        }
      }
      break
    case 'rcw':
      {
        const inputMethod = state.rcw.inputMethod
        const fChoices = state.rcw.choices
        const tempChoices = cloneDeep(fChoices)
        tempChoices.forEach(choice => {
          choice.filtered = false
        })
        toolData = {
          choices: tempChoices,
          mode: 0,
          inputMethod,
          ...(inputMethod === 'ColorWheel'
            ? {
                colorWheel: state.rcw.colorWheel,
              }
            : {
                showMode: state.rcw.showMode,
              }),
        }
      }
      break
    case 'nfl':
    case 'nba':
    case 'mlb':
      {
        const { choices, showMode, conferenceActive, teamsActive } =
          state[sharePage]
        toolData = {
          choices,
          showMode,
          conferenceActive,
          teamsActive,
        }
      }
      break
    case 'rsg':
      {
        const { choices, showMode, countryActive, statesActive } = state.rsg
        toolData = {
          choices,
          showMode,
          countryActive,
          statesActive,
        }
      }
      break
  }

  if (titleChecked) {
    if (toolData === null) {
      // in case if the wheel have no inputs
      toolData = {}
    }
    toolData['title'] = { ...state[sharePage].title }
  }
  if (settingsChecked) {
    if (isPremium && shareMethod === 'Guest') {
      settingsData = cloneDeep(fSettings)

      replaceCustomization(settingsData)
    } else {
      settingsData = { ...fSettings }
    }
    if (settingsData.hasOwnProperty('_persist')) {
      delete settingsData._persist
    }
  }
  const dataFunctions = {
    page: sharePage,
    tool: toolData,
    settings: settingsData,
    websiteMode: shareCanEdit ? 'ShareCanEdit' : 'ShareViewOnly',
    shareMethod,
    noAdsChecked,
    settingsChecked,
  }

  if (user) {
    dataFunctions['userId'] = user.uid
  }

  if (fileKey) {
    dataFunctions['fileKey'] = fileKey
  }

  // if (shareCanEdit) {
  //   dataFunctions['websiteMode'] = 'ShareCanEdit'
  // }

  try {
    if (toolData === null && settingsData === null) {
      // dispatch(asyncActionFinish('share'))
      return {
        path: 'original',
      }
    } else {
      let sameData = shareMethod === 'Guest' ? true : false //guest type init true
      // if (toolData !== null) {
      // if (sharePage === 'main' || sharePage === 'rig' || sharePage === 'rtg') {
      if (equal(dataFunctions, state.share.dataFunctions) === false) {
        //compare same as previous created link
        sameData = false
      }
      // if (shareCanEdit !== state.share.shareCanEdit[sharePage]) {
      //   sameData = false
      // }
      // let resetAllPath = false
      // if (
      //   equal(settingsData, state.share.settings) === false &&
      //   sameData === true
      // ) {
      //   sameData = false
      //   resetAllPath = true // reset all paths as settings apply to all pages
      // }
      // if (state.share.lastSharePath === undefined) {
      //   sameData = false
      // }
      if (sameData) {
        // dispatch(asyncActionFinish('share'))
        return {
          path: state.share.lastSharePath,
        }
      } else {
        const dataLength = JSON.stringify({ ...dataFunctions }).length
        if (dataLength > 990000) {
          //firestore can only store up to 1Mb/doc. check data length less than 1Mb
          // dispatch(asyncActionError('share'))
          throw {
            error: 'Too many images/inputs, reduce some and try again.',
          }
        }
        const timeNow = new Date().getTime() / 1000
        let newLastCreate = lastCreate
        let newCreatedCount = createdCount
        if (lastCreate + 3600 > timeNow) {
          //still within 1 hour
          if (createdCount >= 20) {
            //limit how many times/hour
            // dispatch(asyncActionError('share'))
            throw {
              error:
                'Try again after an hour, you created more than 20 links/hour',
            }
          }
          newCreatedCount = createdCount + 1
        } else {
          newCreatedCount = 1
          newLastCreate = timeNow
        }
        const createShareWheel = httpsCallable(functions, 'createShareWheelV2')
        const result = await createShareWheel({ ...dataFunctions })
        const data = result.data
        if (data.error) {
          console.log(data.error)
          // dispatch(asyncActionError('share'))
          throw {
            error: 'Error when creating the link, please try again.',
          }
        }
        if (shareMethod === 'Guest') {
          //for share as guest
          dispatch(
            keepShareLink(
              dataFunctions,
              newLastCreate,
              newCreatedCount,
              data.path,
            ),
          )
        } else {
          //for share from file
          dispatch(
            updateCurrentConfig({
              fileSharePath: data.path,
            }),
          )
        }

        // dispatch(asyncActionFinish('share'))
        return {
          path: data.path,
        }
      }
    }
  } catch (error) {
    console.log(error)
    // dispatch(asyncActionError('share'))
    throw {
      error: error.error || 'Error when creating the link, please try again.',
    }
  }
}

export const getShareLink = inputs => async (dispatch, getState) => {
  // dispatch(asyncActionStart('getShare'))
  const { sharePage, sharePath } = inputs
  const fSharePath = sharePath.replace(/\//g, '')
  try {
    getFirebase() //it may happen first when site load
    const getShareWheel = httpsCallable(functions, 'getShareWheelV2')
    const result = await getShareWheel({ page: sharePage, path: fSharePath })
    const snap = result.data
    if (snap.error) {
      console.log(snap.error)
      // dispatch(asyncActionError('getShare'))
      throw {
        error: snap.error,
      }
    }
    const data = snap.data
    // if time is beyond this timestamp but have no websitemode written, make it shareviewonly (Aug 12 2022 version default shareviewonly, but default change to sharecanedit after one day), after 3 months can delete this condition

    if (sharePath.length === 8 && data.noAds) {
      dispatch(setSharePremium(true))
      disableAndRemoveAds()
    }
    const websiteMode = data.websiteMode
      ? data.websiteMode
      : data.createdDate._seconds > 1660291860 //equal to Friday, 12 August 2022 16:11:00 GMT+08:00
      ? 'ShareViewOnly'
      : 'ShareCanEdit'
    // const shareCanEdit = websiteMode === 'ShareCanEdit'
    let inputChecked = false
    let settingsChecked = false
    if (sharePage !== 'rtg') {
      // exclude rtg page set website mode
      dispatch(setWebsiteMode(websiteMode))
      const oriState = getState()
      dispatch(
        setPreviousState(sharePage, oriState[sharePage], oriState.settings),
      )
    }
    if (data.settings) {
      settingsChecked = true
      const refactoredMusic = refactorSoundSettings(data.settings.music)
      const refactoredBgColor = refactorBgColorSettings(data.settings.bgColor)
      const refactoredBehavior = refactorSpinBehavior(
        data.settings.spinBehavior,
      )
      const updatedSettings = {
        ...data.settings,
        bgColor: refactoredBgColor,
        music: refactoredMusic,
        spinBehavior: refactoredBehavior,
      }

      if (!updatedSettings.rtgSpinBehavior) {
        //add new rtg behavior
        updatedSettings['rtgSpinBehavior'] = {
          groupingDuration: 5,
          representativeDuration: 3,
        }
      }

      if (data.needReplaceCustomization) {
        replaceCustomization(updatedSettings)
      }
      if (!data.noAds) {
        updatedSettings['banners'] = {
          on: false,
        }
      }
      dispatch(updateAllSettings(updatedSettings))
    }

    if (data.tool) {
      inputChecked = true
      const { title } = data.tool
      if (title) {
        //added 5/7/2021 for title

        titleObjAction[sharePage](refactorTitle(title), dispatch)
      } else {
        titleObjAction[sharePage](
          { active: false, text: '', description: '', popup: '' },
          dispatch,
        )
      }
      if (sharePage === 'main' || sharePage === 'rig') {
        //because only these 2 pages have inputs
        const { choices, mode, weightOn = false } = data.tool
        let inputs = choices
        // if (sharePage === 'rtg') {
        //   inputs = people
        // }
        if (sharePage === 'main') {
          for (let input of inputs) {
            //refactor input.name image to input.data
            if (isImageData(input.name)) {
              input['data'] = input.name
              input.name = ''
            }
          }
          dispatch(setWeightOnMain(weightOn))
        }
        choicesAction[sharePage](inputs, dispatch)
        modesAction[sharePage](mode, dispatch)
        keyTextsAction[sharePage](dispatch)
      } else if (sharePage === 'rcw') {
        const { choices, mode, showMode, inputMethod, colorWheel } = data.tool
        let inputs = choices
        choicesAction[sharePage](inputs, dispatch)
        modesAction[sharePage](mode, dispatch)
        keyTextsAction[sharePage](dispatch)
        dispatch(setInputMethodRCW(inputMethod))
        if (inputMethod === 'ColorWheel') {
          dispatch(updateAllColorWheelStatesRCW(colorWheel))
        } else {
          dispatch(setShowModeRCW(showMode))
        }
      } else if (sharePage === 'rtg') {
        const {
          people,
          numGroups = 2,
          evenSplit = 'Default',
          groupNames = ['', ''],
          showIcon = true,
          pickQuantity = 0,
          pickRepresentative = false,
        } = data.tool
        dispatch(setPeopleRTG(people))
        dispatch(setNumGroupsRTG(numGroups))
        dispatch(setEvenSplitRTG(refactorEvenSplitRTG(evenSplit)))
        dispatch(setGroupNamesRTG(groupNames))
        dispatch(setShowIconRTG(showIcon))
        dispatch(setPickQuantityRTG(pickQuantity))
        dispatch(changeKeyTextRTG(showIcon))
        dispatch(setRepresentativeIndexesRTG([]))
        dispatch(setPickRepresentativeRTG(pickRepresentative))
        dispatch(setPresetGroupsRTG([]))
      } else if (sharePage === 'ynw') {
        const { numInputSet = 4, inputMode = 0 } = data.tool
        dispatch(setNumInputSetYNW(numInputSet))
        dispatch(setInputModeYNW(inputMode))
      } else if (sharePage === 'rlg') {
        const {
          inputMode = 0,
          styleMode = 'UPPERCASE',
          customInput = '',
        } = data.tool
        dispatch(setInputModeRLG(inputMode))
        dispatch(setStyleModeRLG(styleMode))
        dispatch(setCustomInputRLG(customInput))
      } else if (sharePage === 'rcg') {
        const {
          continentsActive = ContinentActiveList,
          showMode = 'Flag & Name',
          choices = [],
        } = data.tool
        dispatch(setContinentsActiveRCG(continentsActive))
        dispatch(setShowModeRCG(showMode))
        dispatch(setChoicesRCG(choices))
      } else if (sharePage === 'rng') {
        const {
          typeOption = 0,
          autoSpinMerge = false,
          minValue = 1,
          maxValue = 10,
          intervalValue = 1,
          excludeFieldValue = '',
          formulaFieldValue = '',
          inputMethod = 'By Range',
          digitNumber,
          digitRanges,
          resultsArray = [null, null, null, null],
        } = data.tool
        const refactoredTypeOption = typeOption === 0 ? 0 : 1
        dispatch(setTypeOptionRNG(refactoredTypeOption))
        if (typeOption === 0) {
          dispatch(setInputMethodRNG(inputMethod))
          if (inputMethod === 'By Range') {
            dispatch(setMinValueRNG(minValue))
            dispatch(setMaxValueRNG(maxValue))
            dispatch(setIntervalValueRNG(intervalValue))
            dispatch(setExcludeFieldValueRNG(excludeFieldValue))
          } else {
            dispatch(setFormulaFieldValueRNG(formulaFieldValue))
          }
        } else {
          dispatch(setAutoSpinMergeRNG(autoSpinMerge))
          dispatch(
            setDigitRangesRNG(
              JSON.parse(digitRanges) || [
                [0, 9],
                [0, 9],
                [0, 9],
                [0, 9],
                [0, 9],
                [0, 9],
              ],
            ),
          )
          dispatch(setDigitNumberRNG(digitNumber || typeOption + 1)) // default 4
          dispatch(setResultsArrayRNG(resultsArray))
        }
      } else if (sharePage === 'rdg') {
        const {
          inputMode = 2,
          daysMode = [
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
            'Sunday',
          ],
          inputs = [],
        } = data.tool
        dispatch(setInputModeRDG(inputMode))
        dispatch(setDaysModeRDG(daysMode))
        dispatch(setInputsRDG(inputs))
      } else if (sharePage === 'nfl') {
        const { conferenceActive, showMode, choices, teamsActive } = data.tool
        dispatch(setConferenceActiveNFL(conferenceActive))
        dispatch(setShowModeNFL(showMode))
        dispatch(setChoicesNFL(choices))
        dispatch(setTeamsActiveNFL(teamsActive))
      } else if (sharePage === 'nba') {
        const { conferenceActive, showMode, choices, teamsActive } = data.tool
        dispatch(setConferenceActiveNBA(conferenceActive))
        dispatch(setShowModeNBA(showMode))
        dispatch(setChoicesNBA(choices))
        dispatch(setTeamsActiveNBA(teamsActive))
      } else if (sharePage === 'rsg') {
        const { countryActive, showMode, choices, statesActive } = data.tool
        dispatch(setCountryActiveRSG(countryActive))
        dispatch(setShowModeRSG(showMode))
        dispatch(setChoicesRSG(choices))
        dispatch(setStatesActiveRSG(statesActive))
      } else if (sharePage === 'mlb') {
        const { conferenceActive, showMode, choices, teamsActive } = data.tool
        dispatch(setConferenceActiveMLB(conferenceActive))
        dispatch(setShowModeMLB(showMode))
        dispatch(setChoicesMLB(choices))
        dispatch(setTeamsActiveMLB(teamsActive))
      }
    }

    // dispatch(asyncActionFinish('getShare'))
    return {
      path: fSharePath,
      // shareConfig: {
      //   shareCanEdit,
      //   inputChecked,
      //   settingsChecked,
      // },
    }
  } catch (error) {
    console.log(error)
    // dispatch(asyncActionError('getShare'))
    throw {
      error: error.error || 'Error when loading the link, please try again.',
    }
  }
}

const replaceCustomization = settingsData => {
  settingsData['banners'] = {
    on: false,
  }
  settingsData['bgImage'] = {
    on: false,
    src: null,
  }
  settingsData['spinButton'] = {
    colorOn: false,
    color: '#3D3D3D',
    imageOn: false,
    image: null,
  }
  settingsData['music'] = {
    ...settingsData['music'],
    customOn: false,
    starting: settingsData.music.starting,
    spinning: settingsData.music.spinning.includes('http')
      ? 'tick-sound'
      : settingsData.music.spinning,
    celebrating: settingsData.music.celebrating.includes('http')
      ? 'celebration-sound'
      : settingsData.music.celebrating,
  }
  settingsData['wheelColors'] = {
    colors:
      settingsData.wheelColors.arrayKey === 'Custom'
        ? ['#2B580C', '#F7B71D', '#AFA939', '#FDEF96']
        : settingsData.wheelColors.colors,
    arrayKey:
      settingsData.wheelColors.arrayKey === 'Custom'
        ? 'Default'
        : settingsData.wheelColors.arrayKey,
    index: settingsData.wheelColors.index,
  }
  settingsData['bgColor'] = {
    color:
      settingsData.bgColor.theme === 'Custom'
        ? '#B1BDA8'
        : settingsData.bgColor.color,
    theme:
      settingsData.bgColor.theme === 'Custom'
        ? 'Default'
        : settingsData.bgColor.theme,
  }
}

export const keepShareLink = (data, lastCreate, createdCount, path) => ({
  type: KEEP_SHARE_LINK,
  payload: {
    data,
    lastCreate,
    createdCount,
    path,
  },
})

// export const setShareConfig = shareConfig => ({
//   type: SET_SHARE_CONFIG,
//   payload: {
//     shareConfig,
//   },
// })

export const updateCurrentConfig = config => ({
  type: UPDATE_CURRENT_CONFIG,
  payload: {
    config,
  },
})

export const instantInit =
  (page, useShareState, isPremium = false) =>
  async (dispatch, getState) => {
    let previousPageState = null
    let previousSettings = null
    if (!useShareState) {
      const state = getState()
      previousPageState = state.share.previousState[page]
      previousSettings = state.share.previousState.settings
    }

    dispatch({
      type: `INSTANT_INIT_${page.toUpperCase()}`,
      payload: {
        previousState: previousPageState,
      },
    })
    if (useShareState && !isPremium) {
      dispatch(turnOffPremiumSettings())
    } else {
      dispatch(instantInitSettings(previousSettings))
    }
    if (!useShareState) {
      dispatch({
        type: `SHOW_TURNTABLE_${page.toUpperCase()}`, //to change wheel colors
        payload: {
          turntable: null,
        },
      })
    }

    // const currentWheelColorsKey = state.settings.wheelColors.arrayKey
    // if (!useShareState || currentWheelColorsKey === 'Custom') {
    //   // redraw to default colors if it is shareViewOnly or is Custom
    //   dispatch({
    //     type: `SHOW_TURNTABLE_${page.toUpperCase()}`, //to change wheel colors
    //     payload: {
    //       turntable: null,
    //     },
    //   })
    // }
  }

const setPreviousState = (page, previousState, settings) => ({
  type: SET_PREVIOUS_STATE,
  payload: {
    previousState: {
      [page]: previousState,
      settings,
    },
  },
})

// export const setFileShareConfig = fileShareConfig => ({
//   type: SET_FILE_SHARE_CONFIG,
//   payload: {
//     fileShareConfig,
//   },
// })

export const setInitialized = status => ({
  type: SET_INITIALIZED,
  payload: {
    status,
  },
})
