// https://global.espn.com/nfl/teams
// https://www.nfl.com/standings/division/2024/REG

const AFCTeams = [
  {
    name: 'Baltimore Ravens',
    data: '/images/nfl/logos/BAL.png',
    division: 'AFC North',
  },
  {
    name: 'Buffalo Bills',
    data: '/images/nfl/logos/BUF.png',
    division: 'AFC East',
  },
  {
    name: 'Cincinnati Bengals',
    data: '/images/nfl/logos/CIN.png',
    division: 'AFC North',
  },
  {
    name: 'Cleveland Browns',
    data: '/images/nfl/logos/CLE.png',
    division: 'AFC North',
  },
  {
    name: 'Denver Broncos',
    data: '/images/nfl/logos/DEN.png',
    division: 'AFC West',
  },
  {
    name: 'Houston Texans',
    data: '/images/nfl/logos/HOU.png',
    division: 'AFC South',
  },
  {
    name: 'Indianapolis Colts',
    data: '/images/nfl/logos/IND.png',
    division: 'AFC South',
  },
  {
    name: 'Jacksonville Jaguars',
    data: '/images/nfl/logos/JAX.png',
    division: 'AFC South',
  },
  {
    name: 'Kansas City Chiefs',
    data: '/images/nfl/logos/KC.png',
    division: 'AFC West',
  },
  {
    name: 'Las Vegas Raiders',
    data: '/images/nfl/logos/LV.png',
    division: 'AFC West',
  },
  {
    name: 'Los Angeles Chargers',
    data: '/images/nfl/logos/LAC.png',
    division: 'AFC West',
  },
  {
    name: 'Miami Dolphins',
    data: '/images/nfl/logos/MIA.png',
    division: 'AFC East',
  },
  {
    name: 'New England Patriots',
    data: '/images/nfl/logos/NE.png',
    division: 'AFC East',
  },
  {
    name: 'New York Jets',
    data: '/images/nfl/logos/NYJ.png',
    division: 'AFC East',
  },
  {
    name: 'Pittsburgh Steelers',
    data: '/images/nfl/logos/PIT.png',
    division: 'AFC North',
  },
  {
    name: 'Tennessee Titans',
    data: '/images/nfl/logos/TEN.png',
    division: 'AFC South',
  },
]

const NFCTeams = [
  {
    name: 'Arizona Cardinals',
    data: '/images/nfl/logos/ARI.png',
    division: 'NFC West',
  },
  {
    name: 'Atlanta Falcons',
    data: '/images/nfl/logos/ATL.png',
    division: 'NFC South',
  },
  {
    name: 'Carolina Panthers',
    data: '/images/nfl/logos/CAR.png',
    division: 'NFC South',
  },
  {
    name: 'Chicago Bears',
    data: '/images/nfl/logos/CHI.png',
    division: 'NFC North',
  },
  {
    name: 'Dallas Cowboys',
    data: '/images/nfl/logos/DAL.png',
    division: 'NFC East',
  },
  {
    name: 'Detroit Lions',
    data: '/images/nfl/logos/DET.png',
    division: 'NFC North',
  },
  {
    name: 'Green Bay Packers',
    data: '/images/nfl/logos/GB.png',
    division: 'NFC North',
  },
  {
    name: 'Los Angeles Rams',
    data: '/images/nfl/logos/LA.png',
    division: 'NFC West',
  },
  {
    name: 'Minnesota Vikings',
    data: '/images/nfl/logos/MIN.png',
    division: 'NFC North',
  },
  {
    name: 'New Orleans Saints',
    data: '/images/nfl/logos/NO.png',
    division: 'NFC South',
  },
  {
    name: 'New York Giants',
    data: '/images/nfl/logos/NYG.png',
    division: 'NFC East',
  },
  {
    name: 'Philadelphia Eagles',
    data: '/images/nfl/logos/PHI.png',
    division: 'NFC East',
  },
  {
    name: 'San Francisco 49ers',
    data: '/images/nfl/logos/SF.png',
    division: 'NFC West',
  },
  {
    name: 'Seattle Seahawks',
    data: '/images/nfl/logos/SEA.png',
    division: 'NFC West',
  },
  {
    name: 'Tampa Bay Buccaneers',
    data: '/images/nfl/logos/TB.png',
    division: 'NFC South',
  },
  {
    name: 'Washington Commanders',
    data: '/images/nfl/logos/WAS.png',
    division: 'NFC East',
  },
]

export const allTeams = [...AFCTeams, ...NFCTeams]

export const ConferenceList = {
  AllTeams: {
    name: 'All Teams',
    dataList: allTeams,
    divisionList: [
      'AFC East',
      'AFC North',
      'AFC South',
      'AFC West',
      'NFC East',
      'NFC North',
      'NFC South',
      'NFC West',
    ],
  },
  AFC: {
    name: 'AFC',
    dataList: AFCTeams,
    divisionList: ['AFC East', 'AFC North', 'AFC South', 'AFC West'],
  },
  NFC: {
    name: 'NFC',
    dataList: NFCTeams,
    divisionList: ['NFC East', 'NFC North', 'NFC South', 'NFC West'],
  },
}
