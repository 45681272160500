import {
  SHOW_TURNTABLE,
  SET_TURNTABLE_SIZE,
  SET_RESULT_SELECTED,
  SET_CHOICES,
  SET_SHOW_MODE,
  SET_COUNTRY_ACTIVE,
  SET_HISTORY,
  SET_TITLE_OBJ,
  SET_MODE,
  SET_STATES_ACTIVE,
} from './constants'
import { setStartRotate } from '../turntable/actions'

export const showTurntable = turntable => ({
  type: SHOW_TURNTABLE,
  payload: {
    turntable,
  },
})

export const setTurntableSize = size => ({
  type: SET_TURNTABLE_SIZE,
  payload: {
    size,
  },
})

export const setResultSelected = (result, resultIndex = -1) => ({
  type: SET_RESULT_SELECTED,
  payload: {
    result,
    resultIndex,
  },
})

export const setHistory = history => ({
  type: SET_HISTORY,
  payload: {
    history,
  },
})

export const setChoices =
  (choices, resetAngle = true) =>
  async (dispatch, getState) => {
    if (resetAngle) {
      const state = getState()
      const startAngle = state.settings.spinBehavior.randomStart
        ? 2 * Math.PI * Math.random()
        : 0
      dispatch(setStartRotate(startAngle))
    }
    dispatch(setChoicesAction(choices))
  }

export const setChoicesAction = (choices, nullTurntable = true) => ({
  type: SET_CHOICES,
  payload: {
    choices,
    nullTurntable,
  },
})

export const setMode = mode => ({
  type: SET_MODE,
  payload: {
    mode,
  },
})

export const setShowMode = showMode => {
  return {
    type: SET_SHOW_MODE,
    payload: {
      showMode,
    },
  }
}

export const setCountryActive = countryActive => {
  return {
    type: SET_COUNTRY_ACTIVE,
    payload: {
      countryActive,
    },
  }
}

export const setTitleActive = active => dispatch => {
  dispatch(setTitleObj({ active }))
}

export const setTitleText = text => dispatch => {
  dispatch(setTitleObj({ text }))
}

export const setTitleObj = obj => {
  return {
    type: SET_TITLE_OBJ,
    payload: {
      obj,
    },
  }
}

export const setStatesActive = statesActive => ({
  type: SET_STATES_ACTIVE,
  payload: {
    statesActive,
  },
})
