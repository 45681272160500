export const United_States = [
  // 50 states
  {
    name: 'Alabama',
    data: '/images/rsg/logos/United_States-Alabama.png',
    region: 'States',
  },
  {
    name: 'Alaska',
    data: '/images/rsg/logos/United_States-Alaska.png',
    region: 'States',
  },
  {
    name: 'Arizona',
    data: '/images/rsg/logos/United_States-Arizona.png',
    region: 'States',
  },
  {
    name: 'Arkansas',
    data: '/images/rsg/logos/United_States-Arkansas.png',
    region: 'States',
  },
  {
    name: 'California',
    data: '/images/rsg/logos/United_States-California.png',
    region: 'States',
  },
  {
    name: 'Colorado',
    data: '/images/rsg/logos/United_States-Colorado.png',
    region: 'States',
  },
  {
    name: 'Connecticut',
    data: '/images/rsg/logos/United_States-Connecticut.png',
    region: 'States',
  },
  {
    name: 'Delaware',
    data: '/images/rsg/logos/United_States-Delaware.png',
    region: 'States',
  },
  {
    name: 'Florida',
    data: '/images/rsg/logos/United_States-Florida.png',
    region: 'States',
  },
  {
    name: 'Georgia',
    data: '/images/rsg/logos/United_States-Georgia.png',
    region: 'States',
  },
  {
    name: 'Hawaii',
    data: '/images/rsg/logos/United_States-Hawaii.png',
    region: 'States',
  },
  {
    name: 'Idaho',
    data: '/images/rsg/logos/United_States-Idaho.png',
    region: 'States',
  },
  {
    name: 'Illinois',
    data: '/images/rsg/logos/United_States-Illinois.png',
    region: 'States',
  },
  {
    name: 'Indiana',
    data: '/images/rsg/logos/United_States-Indiana.png',
    region: 'States',
  },
  {
    name: 'Iowa',
    data: '/images/rsg/logos/United_States-Iowa.png',
    region: 'States',
  },
  {
    name: 'Kansas',
    data: '/images/rsg/logos/United_States-Kansas.png',
    region: 'States',
  },
  {
    name: 'Kentucky',
    data: '/images/rsg/logos/United_States-Kentucky.png',
    region: 'States',
  },
  {
    name: 'Louisiana',
    data: '/images/rsg/logos/United_States-Louisiana.png',
    region: 'States',
  },
  {
    name: 'Maine',
    data: '/images/rsg/logos/United_States-Maine.png',
    region: 'States',
  },
  {
    name: 'Maryland',
    data: '/images/rsg/logos/United_States-Maryland.png',
    region: 'States',
  },
  {
    name: 'Massachusetts',
    data: '/images/rsg/logos/United_States-Massachusetts.png',
    region: 'States',
  },
  {
    name: 'Michigan',
    data: '/images/rsg/logos/United_States-Michigan.png',
    region: 'States',
  },
  {
    name: 'Minnesota',
    data: '/images/rsg/logos/United_States-Minnesota.png',
    region: 'States',
  },
  {
    name: 'Mississippi',
    data: '/images/rsg/logos/United_States-Mississippi.png',
    region: 'States',
  },
  {
    name: 'Missouri',
    data: '/images/rsg/logos/United_States-Missouri.png',
    region: 'States',
  },
  {
    name: 'Montana',
    data: '/images/rsg/logos/United_States-Montana.png',
    region: 'States',
  },
  {
    name: 'Nebraska',
    data: '/images/rsg/logos/United_States-Nebraska.png',
    region: 'States',
  },
  {
    name: 'Nevada',
    data: '/images/rsg/logos/United_States-Nevada.png',
    region: 'States',
  },
  {
    name: 'New Hampshire',
    data: '/images/rsg/logos/United_States-New_Hampshire.png',
    region: 'States',
  },
  {
    name: 'New Jersey',
    data: '/images/rsg/logos/United_States-New_Jersey.png',
    region: 'States',
  },
  {
    name: 'New Mexico',
    data: '/images/rsg/logos/United_States-New_Mexico.png',
    region: 'States',
  },
  {
    name: 'New York',
    data: '/images/rsg/logos/United_States-New_York.png',
    region: 'States',
  },
  {
    name: 'North Carolina',
    data: '/images/rsg/logos/United_States-North_Carolina.png',
    region: 'States',
  },
  {
    name: 'North Dakota',
    data: '/images/rsg/logos/United_States-North_Dakota.png',
    region: 'States',
  },
  {
    name: 'Ohio',
    data: '/images/rsg/logos/United_States-Ohio.png',
    region: 'States',
  },
  {
    name: 'Oklahoma',
    data: '/images/rsg/logos/United_States-Oklahoma.png',
    region: 'States',
  },
  {
    name: 'Oregon',
    data: '/images/rsg/logos/United_States-Oregon.png',
    region: 'States',
  },
  {
    name: 'Pennsylvania',
    data: '/images/rsg/logos/United_States-Pennsylvania.png',
    region: 'States',
  },
  {
    name: 'Rhode Island',
    data: '/images/rsg/logos/United_States-Rhode_Island.png',
    region: 'States',
  },
  {
    name: 'South Carolina',
    data: '/images/rsg/logos/United_States-South_Carolina.png',
    region: 'States',
  },
  {
    name: 'South Dakota',
    data: '/images/rsg/logos/United_States-South_Dakota.png',
    region: 'States',
  },
  {
    name: 'Tennessee',
    data: '/images/rsg/logos/United_States-Tennessee.png',
    region: 'States',
  },
  {
    name: 'Texas',
    data: '/images/rsg/logos/United_States-Texas.png',
    region: 'States',
  },
  {
    name: 'Utah',
    data: '/images/rsg/logos/United_States-Utah.png',
    region: 'States',
  },
  {
    name: 'Vermont',
    data: '/images/rsg/logos/United_States-Vermont.png',
    region: 'States',
  },
  {
    name: 'Virginia',
    data: '/images/rsg/logos/United_States-Virginia.png',
    region: 'States',
  },
  {
    name: 'Washington',
    data: '/images/rsg/logos/United_States-Washington.png',
    region: 'States',
  },
  {
    name: 'West Virginia',
    data: '/images/rsg/logos/United_States-West_Virginia.png',
    region: 'States',
  },
  {
    name: 'Wisconsin',
    data: '/images/rsg/logos/United_States-Wisconsin.png',
    region: 'States',
  },
  {
    name: 'Wyoming',
    data: '/images/rsg/logos/United_States-Wyoming.png',
    region: 'States',
  },
]

const Malaysia = [
  // 13 states + 3 federal territories
  {
    name: 'Johor',
    data: '/images/rsg/logos/Malaysia-Johor.png',
    region: 'States',
  },
  {
    name: 'Kedah',
    data: '/images/rsg/logos/Malaysia-Kedah.png',
    region: 'States',
  },
  {
    name: 'Kelantan',
    data: '/images/rsg/logos/Malaysia-Kelantan.png',
    region: 'States',
  },
  {
    name: 'Melaka',
    data: '/images/rsg/logos/Malaysia-Melaka.png',
    region: 'States',
  },
  {
    name: 'Negeri Sembilan',
    data: '/images/rsg/logos/Malaysia-Negeri_Sembilan.png',
    region: 'States',
  },
  {
    name: 'Pahang',
    data: '/images/rsg/logos/Malaysia-Pahang.png',
    region: 'States',
  },
  {
    name: 'Penang',
    data: '/images/rsg/logos/Malaysia-Penang.png',
    region: 'States',
  },
  {
    name: 'Perak',
    data: '/images/rsg/logos/Malaysia-Perak.png',
    region: 'States',
  },
  {
    name: 'Perlis',
    data: '/images/rsg/logos/Malaysia-Perlis.png',
    region: 'States',
  },
  {
    name: 'Sabah',
    data: '/images/rsg/logos/Malaysia-Sabah.png',
    region: 'States',
  },
  {
    name: 'Sarawak',
    data: '/images/rsg/logos/Malaysia-Sarawak.png',
    region: 'States',
  },
  {
    name: 'Selangor',
    data: '/images/rsg/logos/Malaysia-Selangor.png',
    region: 'States',
  },
  {
    name: 'Terengganu',
    data: '/images/rsg/logos/Malaysia-Terengganu.png',
    region: 'States',
  },
  {
    name: 'Kuala Lumpur',
    data: '/images/rsg/logos/Malaysia-Kuala_Lumpur.png',
    region: 'Federal Territories',
  },
  {
    name: 'Putrajaya',
    data: '/images/rsg/logos/Malaysia-Putrajaya.png',
    region: 'Federal Territories',
  },
  {
    name: 'Labuan',
    data: '/images/rsg/logos/Malaysia-Labuan.png',
    region: 'Federal Territories',
  },
]

const United_Kingdom = [
  // 4 countries
  {
    name: 'England',
    data: '/images/rsg/logos/United_Kingdom-England.png',
    region: 'Countries',
  },
  {
    name: 'Northern Ireland',
    data: '/images/rsg/logos/United_Kingdom-Northern_Ireland.png',
    region: 'Countries',
  },
  {
    name: 'Scotland',
    data: '/images/rsg/logos/United_Kingdom-Scotland.png',
    region: 'Countries',
  },
  {
    name: 'Wales',
    data: '/images/rsg/logos/United_Kingdom-Wales.png',
    region: 'Countries',
  },
]

const Switzerland = [
  // 26 cantons
  {
    name: 'Aargau',
    data: '/images/rsg/logos/Switzerland-Aargau.png',
    region: 'Cantons',
  },
  {
    name: 'Appenzell Ausserrhoden',
    data: '/images/rsg/logos/Switzerland-Appenzell_Ausserrhoden.png',
    region: 'Cantons',
  },
  {
    name: 'Appenzell Innerrhoden',
    data: '/images/rsg/logos/Switzerland-Appenzell_Innerrhoden.png',
    region: 'Cantons',
  },
  {
    name: 'Basel-Landschaft',
    data: '/images/rsg/logos/Switzerland-Basel-Landschaft.png',
    region: 'Cantons',
  },
  {
    name: 'Basel-Stadt',
    data: '/images/rsg/logos/Switzerland-Basel-Stadt.png',
    region: 'Cantons',
  },
  {
    name: 'Bern',
    data: '/images/rsg/logos/Switzerland-Bern.png',
    region: 'Cantons',
  },
  {
    name: 'Fribourg',
    data: '/images/rsg/logos/Switzerland-Fribourg.png',
    region: 'Cantons',
  },
  {
    name: 'Geneva',
    data: '/images/rsg/logos/Switzerland-Geneva.png',
    region: 'Cantons',
  },
  {
    name: 'Glarus',
    data: '/images/rsg/logos/Switzerland-Glarus.png',
    region: 'Cantons',
  },
  {
    name: 'Graubünden',
    data: '/images/rsg/logos/Switzerland-Graubünden.png',
    region: 'Cantons',
  },
  {
    name: 'Jura',
    data: '/images/rsg/logos/Switzerland-Jura.png',
    region: 'Cantons',
  },
  {
    name: 'Lucerne',
    data: '/images/rsg/logos/Switzerland-Lucerne.png',
    region: 'Cantons',
  },
  {
    name: 'Neuchâtel',
    data: '/images/rsg/logos/Switzerland-Neuchâtel.png',
    region: 'Cantons',
  },
  {
    name: 'Nidwalden',
    data: '/images/rsg/logos/Switzerland-Nidwalden.png',
    region: 'Cantons',
  },
  {
    name: 'Obwalden',
    data: '/images/rsg/logos/Switzerland-Obwalden.png',
    region: 'Cantons',
  },
  {
    name: 'Schaffhausen',
    data: '/images/rsg/logos/Switzerland-Schaffhausen.png',
    region: 'Cantons',
  },
  {
    name: 'Schwyz',
    data: '/images/rsg/logos/Switzerland-Schwyz.png',
    region: 'Cantons',
  },
  {
    name: 'Solothurn',
    data: '/images/rsg/logos/Switzerland-Solothurn.png',
    region: 'Cantons',
  },
  {
    name: 'St. Gallen',
    data: '/images/rsg/logos/Switzerland-Sankt_Gallen.png',
    region: 'Cantons',
  },
  {
    name: 'Thurgau',
    data: '/images/rsg/logos/Switzerland-Thurgau.png',
    region: 'Cantons',
  },
  {
    name: 'Ticino',
    data: '/images/rsg/logos/Switzerland-Ticino.png',
    region: 'Cantons',
  },
  {
    name: 'Uri',
    data: '/images/rsg/logos/Switzerland-Uri.png',
    region: 'Cantons',
  },
  {
    name: 'Valais',
    data: '/images/rsg/logos/Switzerland-Valais.png',
    region: 'Cantons',
  },
  {
    name: 'Vaud',
    data: '/images/rsg/logos/Switzerland-Vaud.png',
    region: 'Cantons',
  },
  {
    name: 'Zug',
    data: '/images/rsg/logos/Switzerland-Zug.png',
    region: 'Cantons',
  },
  {
    name: 'Zürich',
    data: '/images/rsg/logos/Switzerland-Zürich.png',
    region: 'Cantons',
  },
]

const Spain = [
  // 17 autonomous communities + 2 autonomous cities
  {
    name: 'Andalusia',
    data: '/images/rsg/logos/Spain-Andalusia.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Aragon',
    data: '/images/rsg/logos/Spain-Aragon.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Asturias',
    data: '/images/rsg/logos/Spain-Asturias.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Balearic Islands',
    data: '/images/rsg/logos/Spain-Balearic_Islands.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Basque Country',
    data: '/images/rsg/logos/Spain-Basque_Country.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Canary Islands',
    data: '/images/rsg/logos/Spain-Canary_Islands.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Cantabria',
    data: '/images/rsg/logos/Spain-Cantabria.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Castile and León',
    data: '/images/rsg/logos/Spain-Castile_and_León.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Castilla-La Mancha',
    data: '/images/rsg/logos/Spain-Castilla-La_Mancha.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Catalonia',
    data: '/images/rsg/logos/Spain-Catalonia.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Extremadura',
    data: '/images/rsg/logos/Spain-Extremadura.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Galicia',
    data: '/images/rsg/logos/Spain-Galicia.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'La Rioja',
    data: '/images/rsg/logos/Spain-La_Rioja.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Madrid',
    data: '/images/rsg/logos/Spain-Madrid.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Murcia',
    data: '/images/rsg/logos/Spain-Murcia.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Navarre',
    data: '/images/rsg/logos/Spain-Navarre.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Valencia',
    data: '/images/rsg/logos/Spain-Valencia.png',
    region: 'Autonomous Communities',
  },
  {
    name: 'Ceuta',
    data: '/images/rsg/logos/Spain-Ceuta.png',
    region: 'Autonomous Cities',
  },
  {
    name: 'Melilla',
    data: '/images/rsg/logos/Spain-Melilla.png',
    region: 'Autonomous Cities',
  },
]

const France = [
  // 18 regions + 5 overseas collectivities +
  // 1 sui generis collectivity + 1 overseas territory + 1 state private property
  {
    name: 'Auvergne-Rhône-Alpes',
    data: '/images/rsg/logos/France-Auvergne-Rhône-Alpes.png',
    region: 'Regions',
  },
  {
    name: 'Bourgogne-Franche-Comté',
    data: '/images/rsg/logos/France-Bourgogne-Franche-Comté.png',
    region: 'Regions',
  },
  {
    name: 'Brittany',
    data: '/images/rsg/logos/France-Brittany.png',
    region: 'Regions',
  },
  {
    name: 'Centre-Val de Loire',
    data: '/images/rsg/logos/France-Centre-Val_de_Loire.png',
    region: 'Regions',
  },
  {
    name: 'Corsica',
    data: '/images/rsg/logos/France-Corsica.png',
    region: 'Regions',
  },
  {
    name: 'Grand Est',
    data: '/images/rsg/logos/France-Grand_Est.png',
    region: 'Regions',
  },
  {
    name: 'Guadeloupe',
    data: '/images/rsg/logos/France-Guadeloupe.png',
    region: 'Regions',
  },
  {
    name: 'French Guiana',
    data: '/images/rsg/logos/France-Guiana.png',
    region: 'Regions',
  },
  {
    name: 'Hauts-de-France',
    data: '/images/rsg/logos/France-Hauts-de-France.png',
    region: 'Regions',
  },
  {
    name: 'Île-de-France',
    data: '/images/rsg/logos/France-Île-de-France.png',
    region: 'Regions',
  },
  {
    name: 'Réunion',
    data: '/images/rsg/logos/France-La_Réunion.png',
    region: 'Regions',
  },
  {
    name: 'Martinique',
    data: '/images/rsg/logos/France-Martinique.png',
    region: 'Regions',
  },
  {
    name: 'Mayotte',
    data: '/images/rsg/logos/France-Mayotte.png',
    region: 'Regions',
  },
  {
    name: 'Normandy',
    data: '/images/rsg/logos/France-Normandy.png',
    region: 'Regions',
  },
  {
    name: 'Nouvelle-Aquitaine',
    data: '/images/rsg/logos/France-Nouvelle-Aquitaine.png',
    region: 'Regions',
  },
  {
    name: 'Occitanie',
    data: '/images/rsg/logos/France-Occitanie.png',
    region: 'Regions',
  },
  {
    name: 'Pays de la Loire',
    data: '/images/rsg/logos/France-Pays_de_la_Loire.png',
    region: 'Regions',
  },
  {
    name: "Provence-Alpes-Côte d'Azur",
    data: "/images/rsg/logos/France-Provence-Alpes-Côte_d'Azur.png",
    region: 'Regions',
  },
  {
    name: 'French Polynesia',
    data: '/images/rsg/logos/France-French_Polynesia.png',
    region: 'Overseas Collectivities',
  },
  {
    name: 'Saint-Barthélemy',
    data: '/images/rsg/logos/France-Saint-Barthélemy.png',
    region: 'Overseas Collectivities',
  },
  {
    name: 'Saint-Martin',
    data: '/images/rsg/logos/France-Saint-Martin.png',
    region: 'Overseas Collectivities',
  },
  {
    name: 'Saint-Pierre and Miquelon',
    data: '/images/rsg/logos/France-Saint-Pierre_and_Miquelon.png',
    region: 'Overseas Collectivities',
  },
  {
    name: 'Wallis and Futuna',
    data: '/images/rsg/logos/France-Wallis_and_Futuna.png',
    region: 'Overseas Collectivities',
  },
  {
    name: 'New Caledonia',
    data: '/images/rsg/logos/France-New_Caledonia.png',
    region: 'Sui Generis Collectivity',
  },
  {
    name: 'French Southern and Antarctic Lands',
    data: '/images/rsg/logos/France-French_Southern_and_Antarctic_Lands.png',
    region: 'Overseas Territory',
  },
  {
    name: 'Clipperton Island',
    data: '/images/rsg/logos/France-Clipperton_Island.png',
    region: 'State Private Property',
  },
]

const Portugal = [
  // 18 districts + 2 autonomous regions
  {
    name: 'Aveiro',
    data: '/images/rsg/logos/Portugal-Aveiro.png',
    region: 'Districts',
  },
  {
    name: 'Azores',
    data: '/images/rsg/logos/Portugal-Azores.png',
    region: 'Autonomous Regions',
  },
  {
    name: 'Beja',
    data: '/images/rsg/logos/Portugal-Beja.png',
    region: 'Districts',
  },
  {
    name: 'Braga',
    data: '/images/rsg/logos/Portugal-Braga.png',
    region: 'Districts',
  },
  {
    name: 'Bragança',
    data: '/images/rsg/logos/Portugal-Bragança.png',
    region: 'Districts',
  },
  {
    name: 'Castelo Branco',
    data: '/images/rsg/logos/Portugal-Castelo_Branco.png',
    region: 'Districts',
  },
  {
    name: 'Coimbra',
    data: '/images/rsg/logos/Portugal-Coimbra.png',
    region: 'Districts',
  },
  {
    name: 'Évora',
    data: '/images/rsg/logos/Portugal-Évora.png',
    region: 'Districts',
  },
  {
    name: 'Faro',
    data: '/images/rsg/logos/Portugal-Faro.png',
    region: 'Districts',
  },
  {
    name: 'Guarda',
    data: '/images/rsg/logos/Portugal-Guarda.png',
    region: 'Districts',
  },
  {
    name: 'Leiria',
    data: '/images/rsg/logos/Portugal-Leiria.png',
    region: 'Districts',
  },
  {
    name: 'Lisbon',
    data: '/images/rsg/logos/Portugal-Lisbon.png',
    region: 'Districts',
  },
  {
    name: 'Madeira',
    data: '/images/rsg/logos/Portugal-Madeira.png',
    region: 'Autonomous Regions',
  },
  {
    name: 'Portalegre',
    data: '/images/rsg/logos/Portugal-Portalegre.png',
    region: 'Districts',
  },
  {
    name: 'Porto',
    data: '/images/rsg/logos/Portugal-Porto.png',
    region: 'Districts',
  },
  {
    name: 'Santarém',
    data: '/images/rsg/logos/Portugal-Santarém.png',
    region: 'Districts',
  },
  {
    name: 'Setúbal',
    data: '/images/rsg/logos/Portugal-Setúbal.png',
    region: 'Districts',
  },
  {
    name: 'Viana do Castelo',
    data: '/images/rsg/logos/Portugal-Viana_do_Castelo.png',
    region: 'Districts',
  },
  {
    name: 'Vila Real',
    data: '/images/rsg/logos/Portugal-Vila_Real.png',
    region: 'Districts',
  },
  {
    name: 'Viseu',
    data: '/images/rsg/logos/Portugal-Viseu.png',
    region: 'Districts',
  },
]

const Australia = [
  // 16 (6 states, 3 internal territories, and 7 external territories)
  {
    name: 'New South Wales',
    data: '/images/rsg/logos/Australia-New_South_Wales.png',
    region: 'States',
  },
  {
    name: 'Queensland',
    data: '/images/rsg/logos/Australia-Queensland.png',
    region: 'States',
  },
  {
    name: 'South Australia',
    data: '/images/rsg/logos/Australia-South_Australia.png',
    region: 'States',
  },
  {
    name: 'Tasmania',
    data: '/images/rsg/logos/Australia-Tasmania.png',
    region: 'States',
  },
  {
    name: 'Victoria',
    data: '/images/rsg/logos/Australia-Victoria.png',
    region: 'States',
  },
  {
    name: 'Western Australia',
    data: '/images/rsg/logos/Australia-Western_Australia.png',
    region: 'States',
  },
  {
    name: 'Australian Capital Territory',
    data: '/images/rsg/logos/Australia-Australian_Capital_Territory.png',
    region: 'Internal Territories',
  },
  {
    name: 'Jervis Bay Territory',
    data: '/images/rsg/logos/Australia.png',
    region: 'Internal Territories',
  },
  {
    name: 'Northern Territory',
    data: '/images/rsg/logos/Australia-Northern_Territory.png',
    region: 'Internal Territories',
  },
  {
    name: 'Ashmore and Cartier Islands',
    data: '/images/rsg/logos/Australia.png',
    region: 'External Territories',
  },
  {
    name: 'Australian Antarctic Territory',
    data: '/images/rsg/logos/Australia-Australian_Antarctic_Territory.png',
    region: 'External Territories',
  },
  {
    name: 'Christmas Island',
    data: '/images/rsg/logos/Australia-Christmas_Island.png',
    region: 'External Territories',
  },
  {
    name: 'Cocos (Keeling) Islands',
    data: '/images/rsg/logos/Australia-Cocos_(Keeling)_Islands.png',
    region: 'External Territories',
  },
  {
    name: 'Coral Sea Islands',
    data: '/images/rsg/logos/Australia-Coral_Sea_Islands.png',
    region: 'External Territories',
  },
  {
    name: 'Heard Island and McDonald Islands',
    data: '/images/rsg/logos/Australia.png',
    region: 'External Territories',
  },
  {
    name: 'Norfolk Island',
    data: '/images/rsg/logos/Australia-Norfolk_Island.png',
    region: 'External Territories',
  },
]

const Denmark = [
  // 5 regions, 2 autonomous territories
  {
    name: 'Hovedstaden',
    data: '/images/rsg/logos/Denmark-Hovedstaden.png',
    region: 'Regions',
    desc: '(Capital Region of Denmark)',
  },
  {
    name: 'Sjælland',
    data: '/images/rsg/logos/Denmark-Sjælland.png',
    region: 'Regions',
    desc: '(Region Zealand)',
  },
  {
    name: 'Syddanmark',
    data: '/images/rsg/logos/Denmark-Syddanmark.png',
    region: 'Regions',
    desc: '(Region of Southern Denmark)',
  },
  {
    name: 'Midtjylland',
    data: '/images/rsg/logos/Denmark-Midtjylland.png',
    region: 'Regions',
    desc: '(Central Denmark Region)',
  },
  {
    name: 'Nordjylland',
    data: '/images/rsg/logos/Denmark-Nordjylland.png',
    region: 'Regions',
    desc: '(North Denmark Region)',
  },
  {
    name: 'Faroe Islands',
    data: '/images/rsg/logos/Denmark-Faroe_Islands.png',
    region: 'Autonomous Territories',
  },
  {
    name: 'Greenland',
    data: '/images/rsg/logos/Denmark-Greenland.png',
    region: 'Autonomous Territories',
  },
]

const Ireland = [
  // 26 counties
  {
    name: 'Carlow',
    data: '/images/rsg/logos/Ireland-Carlow.png',
    region: 'Counties',
  },
  {
    name: 'Cavan',
    data: '/images/rsg/logos/Ireland-Cavan&Laois.png',
    region: 'Counties',
  },
  {
    name: 'Clare',
    data: '/images/rsg/logos/Ireland-Clare.png',
    region: 'Counties',
  },
  {
    name: 'Cork',
    data: '/images/rsg/logos/Ireland-Cork&Louth.png',
    region: 'Counties',
  },
  {
    name: 'Donegal',
    data: '/images/rsg/logos/Ireland-Donegal&Meath.png',
    region: 'Counties',
  },
  {
    name: 'Dublin',
    data: '/images/rsg/logos/Ireland-Dublin.png',
    region: 'Counties',
  },
  {
    name: 'Galway',
    data: '/images/rsg/logos/Ireland-Westmeath&Galway.png',
    region: 'Counties',
  },
  {
    name: 'Kerry',
    data: '/images/rsg/logos/Ireland-Kerry.png',
    region: 'Counties',
  },
  {
    name: 'Kildare',
    data: '/images/rsg/logos/Ireland-Kildare.png',
    region: 'Counties',
  },
  {
    name: 'Kilkenny',
    data: '/images/rsg/logos/Ireland-Kilkenny.png',
    region: 'Counties',
  },
  {
    name: 'Laois',
    data: '/images/rsg/logos/Ireland-Cavan&Laois.png',
    region: 'Counties',
  },
  {
    name: 'Leitrim',
    data: '/images/rsg/logos/Ireland-Leitrim.png',
    region: 'Counties',
  },
  {
    name: 'Limerick',
    data: '/images/rsg/logos/Ireland-Limerick.png',
    region: 'Counties',
  },
  {
    name: 'Longford',
    data: '/images/rsg/logos/Ireland-Longford&Tipperary&Roscommon&Wicklow.png',
    region: 'Counties',
  },
  {
    name: 'Louth',
    data: '/images/rsg/logos/Ireland-Cork&Louth.png',
    region: 'Counties',
  },
  {
    name: 'Mayo',
    data: '/images/rsg/logos/Ireland-Mayo.png',
    region: 'Counties',
  },
  {
    name: 'Meath',
    data: '/images/rsg/logos/Ireland-Donegal&Meath.png',
    region: 'Counties',
  },
  {
    name: 'Monaghan',
    data: '/images/rsg/logos/Ireland-Monaghan.png',
    region: 'Counties',
  },
  {
    name: 'Offaly',
    data: '/images/rsg/logos/Ireland-Offaly.png',
    region: 'Counties',
  },
  {
    name: 'Roscommon',
    data: '/images/rsg/logos/Ireland-Longford&Tipperary&Roscommon&Wicklow.png',
    region: 'Counties',
  },
  {
    name: 'Sligo',
    data: '/images/rsg/logos/Ireland-Sligo.png',
    region: 'Counties',
  },
  {
    name: 'Tipperary',
    data: '/images/rsg/logos/Ireland-Longford&Tipperary&Roscommon&Wicklow.png',
    region: 'Counties',
  },
  {
    name: 'Waterford',
    data: '/images/rsg/logos/Ireland-Waterford.png',
    region: 'Counties',
  },
  {
    name: 'Westmeath',
    data: '/images/rsg/logos/Ireland-Westmeath&Galway.png',
    region: 'Counties',
  },
  {
    name: 'Wexford',
    data: '/images/rsg/logos/Ireland-Wexford.png',
    region: 'Counties',
  },
  {
    name: 'Wicklow',
    data: '/images/rsg/logos/Ireland-Longford&Tipperary&Roscommon&Wicklow.png',
    region: 'Counties',
  },
]

const Norway = [
  // 14 counties + 1 combined municipality and county + 2 integral overseas areas + 2 dependencies
  {
    name: 'Agder',
    data: '/images/rsg/logos/Norway-Agder.png',
    region: 'Counties',
  },
  {
    name: 'Akershus',
    data: '/images/rsg/logos/Norway-Akershus.png',
    region: 'Counties',
  },
  {
    name: 'Buskerud',
    data: '/images/rsg/logos/Norway-Buskerud.png',
    region: 'Counties',
  },
  {
    name: 'Finnmark',
    data: '/images/rsg/logos/Norway-Finnmark.png',
    region: 'Counties',
  },
  {
    name: 'Østfold',
    data: '/images/rsg/logos/Norway-Østfold.png',
    region: 'Counties',
  },
  {
    name: 'Innlandet',
    data: '/images/rsg/logos/Norway-Innlandet.png',
    region: 'Counties',
  },
  {
    name: 'Møre og Romsdal',
    data: '/images/rsg/logos/Norway-Møre_og_Romsdal.png',
    region: 'Counties',
  },
  {
    name: 'Nordland',
    data: '/images/rsg/logos/Norway-Nordland.png',
    region: 'Counties',
  },
  {
    name: 'Oslo',
    data: '/images/rsg/logos/Norway-Oslo.png',
    region: 'Combined Municipality and County',
  },
  {
    name: 'Rogaland',
    data: '/images/rsg/logos/Norway-Rogaland.png',
    region: 'Counties',
  },
  {
    name: 'Telemark',
    data: '/images/rsg/logos/Norway-Telemark.png',
    region: 'Counties',
  },
  {
    name: 'Troms',
    data: '/images/rsg/logos/Norway-Troms.png',
    region: 'Counties',
  },
  {
    name: 'Trøndelag',
    data: '/images/rsg/logos/Norway-Trøndelag.png',
    region: 'Counties',
  },
  {
    name: 'Vestfold',
    data: '/images/rsg/logos/Norway-Vestfold.png',
    region: 'Counties',
  },
  {
    name: 'Vestland',
    data: '/images/rsg/logos/Norway-Vestland.png',
    region: 'Counties',
  },
  {
    name: 'Jan Mayen',
    data: '/images/rsg/logos/Norway.png',
    region: 'Integral Overseas Areas',
  },
  {
    name: 'Svalbard',
    data: '/images/rsg/logos/Norway.png',
    region: 'Integral Overseas Areas',
  },
  {
    name: 'Bouvet Island',
    data: '/images/rsg/logos/Norway.png',
    region: 'Dependencies',
  },
  {
    name: 'Peter I Island',
    data: '/images/rsg/logos/Norway.png',
    region: 'Dependencies',
  },
  {
    name: 'Queen Maud Land',
    data: '/images/rsg/logos/Norway.png',
    region: 'Dependencies',
  },
]

const Italy = [
  // 15 ordinary regions + autonomous regions
  {
    name: 'Abruzzo',
    data: '/images/rsg/logos/Italy-Abruzzo.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Aosta Valley',
    data: '/images/rsg/logos/Italy-Aosta_Valley.png',
    region: 'Autonomous Regions',
  },
  {
    name: 'Apulia',
    data: '/images/rsg/logos/Italy-Apulia.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Basilicata',
    data: '/images/rsg/logos/Italy-Basilicata.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Calabria',
    data: '/images/rsg/logos/Italy-Calabria.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Campania',
    data: '/images/rsg/logos/Italy-Campania.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Emilia-Romagna',
    data: '/images/rsg/logos/Italy-Emilia-Romagna.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Friuli-Venezia Giulia',
    data: '/images/rsg/logos/Italy-Friuli-Venezia_Giulia.png',
    region: 'Autonomous Regions',
  },
  {
    name: 'Lazio',
    data: '/images/rsg/logos/Italy-Lazio.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Liguria',
    data: '/images/rsg/logos/Italy-Liguria.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Lombardy',
    data: '/images/rsg/logos/Italy-Lombardy.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Marche',
    data: '/images/rsg/logos/Italy-Marche.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Molise',
    data: '/images/rsg/logos/Italy-Molise.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Piedmont',
    data: '/images/rsg/logos/Italy-Piedmont.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Sardinia',
    data: '/images/rsg/logos/Italy-Sardinia.png',
    region: 'Autonomous Regions',
  },
  {
    name: 'Sicily',
    data: '/images/rsg/logos/Italy-Sicily.png',
    region: 'Autonomous Regions',
  },
  {
    name: 'Trentino-South Tyrol',
    data: '/images/rsg/logos/Italy-Trentino-South_Tyrol.png',
    region: 'Autonomous Regions',
  },
  {
    name: 'Tuscany',
    data: '/images/rsg/logos/Italy-Tuscany.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Umbria',
    data: '/images/rsg/logos/Italy-Umbria.png',
    region: 'Ordinary Regions',
  },
  {
    name: 'Veneto',
    data: '/images/rsg/logos/Italy-Veneto.png',
    region: 'Ordinary Regions',
  },
]

const Netherlands = [
  // 4 constituent countries
  {
    name: 'Aruba',
    data: '/images/rsg/logos/Netherlands-Aruba.png',
    region: 'Constituent Countries',
  },
  {
    name: 'Curaçao',
    data: '/images/rsg/logos/Netherlands-Curaçao.png',
    region: 'Constituent Countries',
  },
  {
    name: 'Sint Maarten',
    data: '/images/rsg/logos/Netherlands-Sint_Maarten.png',
    region: 'Constituent Countries',
  },
  {
    name: 'Netherlands',
    data: '/images/rsg/logos/Netherlands.png',
    region: 'Constituent Countries',
  },
]

const Canada = [
  // 10 provinces + 3 territories
  {
    name: 'Alberta',
    data: '/images/rsg/logos/Canada-Alberta.png',
    region: 'Provinces',
  },
  {
    name: 'British Columbia',
    data: '/images/rsg/logos/Canada-British_Columbia.png',
    region: 'Provinces',
  },
  {
    name: 'Manitoba',
    data: '/images/rsg/logos/Canada-Manitoba.png',
    region: 'Provinces',
  },
  {
    name: 'New Brunswick',
    data: '/images/rsg/logos/Canada-New_Brunswick.png',
    region: 'Provinces',
  },
  {
    name: 'Newfoundland and Labrador',
    data: '/images/rsg/logos/Canada-Newfoundland_and_Labrador.png',
    region: 'Provinces',
  },
  {
    name: 'Nova Scotia',
    data: '/images/rsg/logos/Canada-Nova_Scotia.png',
    region: 'Provinces',
  },
  {
    name: 'Ontario',
    data: '/images/rsg/logos/Canada-Ontario.png',
    region: 'Provinces',
  },
  {
    name: 'Prince Edward Island',
    data: '/images/rsg/logos/Canada-Prince_Edward_Island.png',
    region: 'Provinces',
  },
  {
    name: 'Quebec',
    data: '/images/rsg/logos/Canada-Quebec.png',
    region: 'Provinces',
  },
  {
    name: 'Saskatchewan',
    data: '/images/rsg/logos/Canada-Saskatchewan.png',
    region: 'Provinces',
  },
  {
    name: 'Northwest Territories',
    data: '/images/rsg/logos/Canada-Northwest-Territories.png',
    region: 'Territories',
  },
  {
    name: 'Nunavut',
    data: '/images/rsg/logos/Canada-Nunavut.png',
    region: 'Territories',
  },
  {
    name: 'Yukon',
    data: '/images/rsg/logos/Canada-Yukon.png',
    region: 'Territories',
  },
]

const Germany = [
  // 16 states
  {
    name: 'Baden-Württemberg',
    data: '/images/rsg/logos/Germany-Baden-Württemberg.png',
    region: 'States',
  },
  {
    name: 'Bavaria',
    data: '/images/rsg/logos/Germany-Bavaria.png',
    region: 'States',
  },
  {
    name: 'Brandenburg',
    data: '/images/rsg/logos/Germany-Brandenburg.png',
    region: 'States',
  },
  {
    name: 'Berlin',
    data: '/images/rsg/logos/Germany-Berlin.png',
    region: 'States',
  },
  {
    name: 'Bremen',
    data: '/images/rsg/logos/Germany-Bremen.png',
    region: 'States',
  },
  {
    name: 'Hamburg',
    data: '/images/rsg/logos/Germany-Hamburg.png',
    region: 'States',
  },
  {
    name: 'Hesse',
    data: '/images/rsg/logos/Germany-Hesse.png',
    region: 'States',
  },
  {
    name: 'Lower Saxony',
    data: '/images/rsg/logos/Germany-Lower_Saxony.png',
    region: 'States',
  },
  {
    name: 'Mecklenburg-Western Pomerania',
    data: '/images/rsg/logos/Germany-Mecklenburg-Western_Pomerania.png',
    region: 'States',
  },
  {
    name: 'North Rhine-Westphalia',
    data: '/images/rsg/logos/Germany-North_Rhine-Westphalia.png',
    region: 'States',
  },
  {
    name: 'Rhineland-Palatinate',
    data: '/images/rsg/logos/Germany-Rhineland-Palatinate.png',
    region: 'States',
  },
  {
    name: 'Saarland',
    data: '/images/rsg/logos/Germany-Saarland.png',
    region: 'States',
  },
  {
    name: 'Saxony-Anhalt',
    data: '/images/rsg/logos/Germany-Saxony-Anhalt.png',
    region: 'States',
  },
  {
    name: 'Saxony',
    data: '/images/rsg/logos/Germany-Saxony.png',
    region: 'States',
  },
  {
    name: 'Schleswig-Holstein',
    data: '/images/rsg/logos/Germany-Schleswig-Holstein.png',
    region: 'States',
  },
  {
    name: 'Thuringia',
    data: '/images/rsg/logos/Germany-Thuringia.png',
    region: 'States',
  },
]

const Luxembourg = [
  // 12 cantons
  {
    name: 'Capellen',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Clervaux',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Diekirch',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Echternach',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Esch-sur-Alzette',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Grevenmacher',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Luxembourg',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Mersch',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Redange',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Remich',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Vianden',
    data: '',
    region: 'Cantons',
  },
  {
    name: 'Wiltz',
    data: '',
    region: 'Cantons',
  },
]

const Sweden = [
  // 21 counties
  {
    name: 'Blekinge',
    data: '/images/rsg/logos/Sweden-Blekinge.png',
    region: 'Counties',
  },
  {
    name: 'Dalarna',
    data: '/images/rsg/logos/Sweden-Dalarna.png',
    region: 'Counties',
  },
  {
    name: 'Gävleborg',
    data: '/images/rsg/logos/Sweden-Gävleborg.png',
    region: 'Counties',
  },
  {
    name: 'Gotland',
    data: '/images/rsg/logos/Sweden-Gotland.png',
    region: 'Counties',
  },
  {
    name: 'Halland',
    data: '/images/rsg/logos/Sweden-Halland.png',
    region: 'Counties',
  },
  {
    name: 'Jämtland',
    data: '/images/rsg/logos/Sweden-Jämtland.png',
    region: 'Counties',
  },
  {
    name: 'Jönköping',
    data: '/images/rsg/logos/Sweden-Jönköping.png',
    region: 'Counties',
  },
  {
    name: 'Kalmar',
    data: '/images/rsg/logos/Sweden-Kalmar.png',
    region: 'Counties',
  },
  {
    name: 'Kronoberg',
    data: '/images/rsg/logos/Sweden-Kronoberg.png',
    region: 'Counties',
  },
  {
    name: 'Norrbotten',
    data: '/images/rsg/logos/Sweden-Norrbotten.png',
    region: 'Counties',
  },
  {
    name: 'Örebro',
    data: '/images/rsg/logos/Sweden-Örebro.png',
    region: 'Counties',
  },
  {
    name: 'Östergötland',
    data: '/images/rsg/logos/Sweden-Östergötland.png',
    region: 'Counties',
  },
  {
    name: 'Skåne',
    data: '/images/rsg/logos/Sweden-Skåne.png',
    region: 'Counties',
  },
  {
    name: 'Södermanland',
    data: '/images/rsg/logos/Sweden-Södermanland.png',
    region: 'Counties',
  },
  {
    name: 'Stockholm',
    data: '/images/rsg/logos/Sweden-Stockholm.png',
    region: 'Counties',
  },
  {
    name: 'Uppsala',
    data: '/images/rsg/logos/Sweden-Uppsala.png',
    region: 'Counties',
  },
  {
    name: 'Värmland',
    data: '/images/rsg/logos/Sweden-Värmland.png',
    region: 'Counties',
  },
  {
    name: 'Västerbotten',
    data: '/images/rsg/logos/Sweden-Västerbotten.png',
    region: 'Counties',
  },
  {
    name: 'Västernorrland',
    data: '/images/rsg/logos/Sweden-Västernorrland.png',
    region: 'Counties',
  },
  {
    name: 'Västmanland',
    data: '/images/rsg/logos/Sweden-Västmanland.png',
    region: 'Counties',
  },
  {
    name: 'Västra Götaland',
    data: '/images/rsg/logos/Sweden-Västra_Götaland.png',
    region: 'Counties',
  },
]

const New_Zealand = [
  // 16 regions
  {
    name: 'Northland',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Auckland',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Waikato',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Bay of Plenty',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Gisborne',
    data: '',
    region: 'Regions',
  },
  {
    name: `Hawke's Bay`,
    data: '',
    region: 'Regions',
  },
  {
    name: 'Taranaki',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Manawatū-Whanganui',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Wellington',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Tasman',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Nelson',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Marlborough',
    data: '',
    region: 'Regions',
  },
  {
    name: 'West Coast',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Canterbury',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Otago',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Southland',
    data: '',
    region: 'Regions',
  },
  {
    name: 'Chatham Islands',
    data: '',
    region: 'Special Territorial Authority',
  },
]

const Belgium = [
  // 3 regions
  {
    name: 'Flanders',
    data: '/images/rsg/logos/Belgium-Flanders.png',
    region: 'Regions',
    desc: '(Flemish Region)',
  },
  {
    name: 'Wallonia',
    data: '/images/rsg/logos/Belgium-Wallonia.png',
    region: 'Regions',
    desc: '(Walloon Region)',
  },
  {
    name: 'Brussels',
    data: '/images/rsg/logos/Belgium-Brussels.png',
    region: 'Regions',
    desc: '(Brussels-Capital Region)',
  },
]

const Finland = [
  // 18 regions + 1 autonomous region
  {
    name: 'Åland',
    data: '/images/rsg/logos/Finland-Åland.png',
    region: 'Autonomous Region',
  },
  {
    name: 'Central Finland',
    data: '/images/rsg/logos/Finland-Central_Finland.png',
    region: 'Regions',
  },
  {
    name: 'Central Ostrobothnia',
    data: '/images/rsg/logos/Finland-Central_Ostrobothnia.png',
    region: 'Regions',
  },
  {
    name: 'Kainuu',
    data: '/images/rsg/logos/Finland-Kainuu.png',
    region: 'Regions',
  },
  {
    name: 'Kanta-Häme',
    data: '/images/rsg/logos/Finland-Kanta-Häme.png',
    region: 'Regions',
  },
  {
    name: 'Kymenlaakso',
    data: '/images/rsg/logos/Finland-Kymenlaakso.png',
    region: 'Regions',
  },
  {
    name: 'Lapland',
    data: '/images/rsg/logos/Finland-Lapland.png',
    region: 'Regions',
  },
  {
    name: 'North Karelia',
    data: '/images/rsg/logos/Finland-North_Karelia.png',
    region: 'Regions',
  },
  {
    name: 'North Ostrobothnia',
    data: '/images/rsg/logos/Finland-North_Ostrobothnia.png',
    region: 'Regions',
  },
  {
    name: 'North Savo',
    data: '/images/rsg/logos/Finland-North_Savo.png',
    region: 'Regions',
  },
  {
    name: 'Ostrobothnia',
    data: '/images/rsg/logos/Finland-Ostrobothnia.png',
    region: 'Regions',
  },
  {
    name: 'Päijät-Häme',
    data: '/images/rsg/logos/Finland-Päijät-Häme.png',
    region: 'Regions',
  },
  {
    name: 'Pirkanmaa',
    data: '/images/rsg/logos/Finland-Pirkanmaa.png',
    region: 'Regions',
  },
  {
    name: 'Satakunta',
    data: '/images/rsg/logos/Finland-Satakunta.png',
    region: 'Regions',
  },
  {
    name: 'South Karelia',
    data: '/images/rsg/logos/Finland-South_Karelia.png',
    region: 'Regions',
  },
  {
    name: 'South Ostrobothnia',
    data: '/images/rsg/logos/Finland-South_Ostrobothnia.png',
    region: 'Regions',
  },
  {
    name: 'South Savo',
    data: '/images/rsg/logos/Finland-South_Savo.png',
    region: 'Regions',
  },
  {
    name: 'Southwest Finland',
    data: '/images/rsg/logos/Finland-Southwest_Finland.png',
    region: 'Regions',
  },
  {
    name: 'Uusimaa',
    data: '/images/rsg/logos/Finland-Uusimaa.png',
    region: 'Regions',
  },
]

const Austria = [
  // 9 federal states
  {
    name: 'Burgenland',
    data: '/images/rsg/logos/Austria-Burgenland.png',
    region: 'Federal States',
  },
  {
    name: 'Carinthia',
    data: '/images/rsg/logos/Austria-Carinthia.png',
    region: 'Federal States',
  },
  {
    name: 'Lower Austria',
    data: '/images/rsg/logos/Austria-Lower_Austria.png',
    region: 'Federal States',
  },
  {
    name: 'Salzburg',
    data: '/images/rsg/logos/Austria-Salzburg&Vienna&Vorarlberg.png',
    region: 'Federal States',
  },
  {
    name: 'Styria',
    data: '/images/rsg/logos/Austria-Styria.png',
    region: 'Federal States',
  },
  {
    name: 'Tyrol',
    data: '/images/rsg/logos/Austria-Tyrol&Upper_Austria.png',
    region: 'Federal States',
  },
  {
    name: 'Upper Austria',
    data: '/images/rsg/logos/Austria-Tyrol&Upper_Austria.png',
    region: 'Federal States',
  },
  {
    name: 'Vienna',
    data: '/images/rsg/logos/Austria-Salzburg&Vienna&Vorarlberg.png',
    region: 'Federal States',
  },
  {
    name: 'Vorarlberg',
    data: '/images/rsg/logos/Austria-Salzburg&Vienna&Vorarlberg.png',
    region: 'Federal States',
  },
]

const Singapore = [
  // 5 districts
  {
    name: 'Central Singapore',
    data: '',
    region: 'Districts',
  },
  {
    name: 'North East',
    data: '',
    region: 'Districts',
  },
  {
    name: 'North West',
    data: '',
    region: 'Districts',
  },
  {
    name: 'South East',
    data: '',
    region: 'Districts',
  },
  {
    name: 'South West',
    data: '',
    region: 'Districts',
  },
]

const Japan = [
  // 47 prefectures
  {
    name: 'Aichi',
    data: '/images/rsg/logos/Japan-Aichi.png',
    region: 'Prefectures',
  },
  {
    name: 'Akita',
    data: '/images/rsg/logos/Japan-Akita.png',
    region: 'Prefectures',
  },
  {
    name: 'Aomori',
    data: '/images/rsg/logos/Japan-Aomori.png',
    region: 'Prefectures',
  },
  {
    name: 'Chiba',
    data: '/images/rsg/logos/Japan-Chiba.png',
    region: 'Prefectures',
  },
  {
    name: 'Ehime',
    data: '/images/rsg/logos/Japan-Ehime.png',
    region: 'Prefectures',
  },
  {
    name: 'Fukui',
    data: '/images/rsg/logos/Japan-Fukui.png',
    region: 'Prefectures',
  },
  {
    name: 'Fukuoka',
    data: '/images/rsg/logos/Japan-Fukuoka.png',
    region: 'Prefectures',
  },
  {
    name: 'Fukushima',
    data: '/images/rsg/logos/Japan-Fukushima.png',
    region: 'Prefectures',
  },
  {
    name: 'Gifu',
    data: '/images/rsg/logos/Japan-Gifu.png',
    region: 'Prefectures',
  },
  {
    name: 'Gunma',
    data: '/images/rsg/logos/Japan-Gunma.png',
    region: 'Prefectures',
  },
  {
    name: 'Hiroshima',
    data: '/images/rsg/logos/Japan-Hiroshima.png',
    region: 'Prefectures',
  },
  {
    name: 'Hokkaido',
    data: '/images/rsg/logos/Japan-Hokkaido.png',
    region: 'Prefectures',
  },
  {
    name: 'Hyōgo',
    data: '/images/rsg/logos/Japan-Hyōgo.png',
    region: 'Prefectures',
  },
  {
    name: 'Ibaraki',
    data: '/images/rsg/logos/Japan-Ibaraki.png',
    region: 'Prefectures',
  },
  {
    name: 'Ishikawa',
    data: '/images/rsg/logos/Japan-Ishikawa.png',
    region: 'Prefectures',
  },
  {
    name: 'Iwate',
    data: '/images/rsg/logos/Japan-Iwate.png',
    region: 'Prefectures',
  },
  {
    name: 'Kagawa',
    data: '/images/rsg/logos/Japan-Kagawa.png',
    region: 'Prefectures',
  },
  {
    name: 'Kagoshima',
    data: '/images/rsg/logos/Japan-Kagoshima.png',
    region: 'Prefectures',
  },
  {
    name: 'Kanagawa',
    data: '/images/rsg/logos/Japan-Kanagawa.png',
    region: 'Prefectures',
  },
  {
    name: 'Kōchi',
    data: '/images/rsg/logos/Japan-Kōchi.png',
    region: 'Prefectures',
  },
  {
    name: 'Kumamoto',
    data: '/images/rsg/logos/Japan-Kumamoto.png',
    region: 'Prefectures',
  },
  {
    name: 'Kyoto',
    data: '/images/rsg/logos/Japan-Kyoto.png',
    region: 'Prefectures',
  },
  {
    name: 'Mie',
    data: '/images/rsg/logos/Japan-Mie.png',
    region: 'Prefectures',
  },
  {
    name: 'Miyagi',
    data: '/images/rsg/logos/Japan-Miyagi.png',
    region: 'Prefectures',
  },
  {
    name: 'Miyazaki',
    data: '/images/rsg/logos/Japan-Miyazaki.png',
    region: 'Prefectures',
  },
  {
    name: 'Nagano',
    data: '/images/rsg/logos/Japan-Nagano.png',
    region: 'Prefectures',
  },
  {
    name: 'Nagasaki',
    data: '/images/rsg/logos/Japan-Nagasaki.png',
    region: 'Prefectures',
  },
  {
    name: 'Nara',
    data: '/images/rsg/logos/Japan-Nara.png',
    region: 'Prefectures',
  },
  {
    name: 'Niigata',
    data: '/images/rsg/logos/Japan-Niigata.png',
    region: 'Prefectures',
  },
  {
    name: 'Oita',
    data: '/images/rsg/logos/Japan-Oita.png',
    region: 'Prefectures',
  },
  {
    name: 'Okayama',
    data: '/images/rsg/logos/Japan-Okayama.png',
    region: 'Prefectures',
  },
  {
    name: 'Okinawa',
    data: '/images/rsg/logos/Japan-Okinawa.png',
    region: 'Prefectures',
  },
  {
    name: 'Osaka',
    data: '/images/rsg/logos/Japan-Osaka.png',
    region: 'Prefectures',
  },
  {
    name: 'Saga',
    data: '/images/rsg/logos/Japan-Saga.png',
    region: 'Prefectures',
  },
  {
    name: 'Saitama',
    data: '/images/rsg/logos/Japan-Saitama.png',
    region: 'Prefectures',
  },
  {
    name: 'Shiga',
    data: '/images/rsg/logos/Japan-Shiga.png',
    region: 'Prefectures',
  },
  {
    name: 'Shimane',
    data: '/images/rsg/logos/Japan-Shimane.png',
    region: 'Prefectures',
  },
  {
    name: 'Shizuoka',
    data: '/images/rsg/logos/Japan-Shizuoka.png',
    region: 'Prefectures',
  },
  {
    name: 'Tochigi',
    data: '/images/rsg/logos/Japan-Tochigi.png',
    region: 'Prefectures',
  },
  {
    name: 'Tokushima',
    data: '/images/rsg/logos/Japan-Tokushima.png',
    region: 'Prefectures',
  },
  {
    name: 'Tokyo',
    data: '/images/rsg/logos/Japan-Tokyo.png',
    region: 'Prefectures',
  },
  {
    name: 'Tottori',
    data: '/images/rsg/logos/Japan-Tottori.png',
    region: 'Prefectures',
  },
  {
    name: 'Toyama',
    data: '/images/rsg/logos/Japan-Toyama.png',
    region: 'Prefectures',
  },
  {
    name: 'Wakayama',
    data: '/images/rsg/logos/Japan-Wakayama.png',
    region: 'Prefectures',
  },
  {
    name: 'Yamagata',
    data: '/images/rsg/logos/Japan-Yamagata.png',
    region: 'Prefectures',
  },
  {
    name: 'Yamaguchi',
    data: '/images/rsg/logos/Japan-Yamaguchi.png',
    region: 'Prefectures',
  },
  {
    name: 'Yamanashi',
    data: '/images/rsg/logos/Japan-Yamanashi.png',
    region: 'Prefectures',
  },
]

const South_Korea = [
  // 17 (9 provinces + 8 cities)
  {
    name: 'Gangwon',
    data: '/images/rsg/logos/South_Korea-Gangwon.png',
    region: 'Provinces',
  },
  {
    name: 'Gyeonggi',
    data: '/images/rsg/logos/South_Korea-Gyeonggi.png',
    region: 'Provinces',
  },
  {
    name: 'Jeju',
    data: '/images/rsg/logos/South_Korea-Jeju.png',
    region: 'Provinces',
  },
  {
    name: 'North Chungcheong',
    data: '/images/rsg/logos/South_Korea-North_Chungcheong.png',
    region: 'Provinces',
  },
  {
    name: 'North Gyeongsang',
    data: '/images/rsg/logos/South_Korea-North_Gyeongsang.png',
    region: 'Provinces',
  },
  {
    name: 'North Jeolla',
    data: '/images/rsg/logos/South_Korea-North_Jeolla.png',
    region: 'Provinces',
  },
  {
    name: 'South Chungcheong',
    data: '/images/rsg/logos/South_Korea-South_Chungcheong.png',
    region: 'Provinces',
  },
  {
    name: 'South Gyeongsang',
    data: '/images/rsg/logos/South_Korea-South_Gyeongsang.png',
    region: 'Provinces',
  },
  {
    name: 'South Jeolla',
    data: '/images/rsg/logos/South_Korea-South_Jeolla.png',
    region: 'Provinces',
  },
  {
    name: 'Seoul',
    data: '/images/rsg/logos/South_Korea-Seoul.png',
    region: 'Cities',
  },
  {
    name: 'Busan',
    data: '/images/rsg/logos/South_Korea-Busan.png',
    region: 'Cities',
  },
  {
    name: 'Daegu',
    data: '/images/rsg/logos/South_Korea-Daegu.png',
    region: 'Cities',
  },
  {
    name: 'Incheon',
    data: '/images/rsg/logos/South_Korea-Incheon.png',
    region: 'Cities',
  },
  {
    name: 'Gwangju',
    data: '/images/rsg/logos/South_Korea-Gwangju.png',
    region: 'Cities',
  },
  {
    name: 'Daejeon',
    data: '/images/rsg/logos/South_Korea-Daejeon.png',
    region: 'Cities',
  },
  {
    name: 'Sejong',
    data: '/images/rsg/logos/South_Korea-Sejong.png',
    region: 'Cities',
  },
  {
    name: 'Ulsan',
    data: '/images/rsg/logos/South_Korea-Ulsan.png',
    region: 'Cities',
  },
]

const China = [
  // 23 provinces, 4 municipalities + 2 special administrative regions
  {
    name: 'Anhui',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Fujian',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Gansu',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Guangdong',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Guizhou',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Hainan',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Hebei',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Heilongjiang',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Henan',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Hubei',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Hunan',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Jiangsu',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Jiangxi',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Jilin',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Liaoning',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Qinghai',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Shaanxi',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Shandong',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Shanxi',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Sichuan',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Yunnan',
    data: '',
    region: 'Provinces',
  },
  {
    name: 'Zhejiang',
    data: '',
    region: 'Provinces',
  },
  // {
  //   name: 'Taiwan',
  //   data: '',
  //   region: 'Provinces',
  // },
  {
    name: 'Inner Mongolia',
    data: '',
    region: 'Autonomous Regions',
  },
  {
    name: 'Guangxi',
    data: '',
    region: 'Autonomous Regions',
  },
  {
    name: 'Tibet',
    data: '',
    region: 'Autonomous Regions',
  },
  {
    name: 'Ningxia',
    data: '',
    region: 'Autonomous Regions',
  },
  {
    name: 'Xinjiang',
    data: '',
    region: 'Autonomous Regions',
  },
  {
    name: 'Beijing',
    data: '',
    region: 'Municipalities',
  },
  {
    name: 'Chongqing',
    data: '',
    region: 'Municipalities',
  },
  {
    name: 'Shanghai',
    data: '',
    region: 'Municipalities',
  },
  {
    name: 'Tianjin',
    data: '',
    region: 'Municipalities',
  },
  // {
  //   name: 'Hong Kong',
  //   data: '',
  //   region: 'Special Administrative Regions',
  // },
  // {
  //   name: 'Macau',
  //   data: '',
  //   region: 'Special Administrative Regions',
  // },
]

const Thailand = [
  // 76 provinces + 1 special administrative area
  {
    name: 'Bangkok',
    data: '/images/rsg/logos/Thailand-Bangkok.png',
    region: 'Special Administrative Area',
  },
  {
    name: 'Amnat Charoen',
    data: '/images/rsg/logos/Thailand-Amnat_Charoen.png',
    region: 'Provinces',
  },
  {
    name: 'Ang Thong',
    data: '/images/rsg/logos/Thailand-Ang_Thong.png',
    region: 'Provinces',
  },
  {
    name: 'Bueng Kan',
    data: '/images/rsg/logos/Thailand-Bueng_Kan.png',
    region: 'Provinces',
  },
  {
    name: 'Buriram',
    data: '/images/rsg/logos/Thailand-Buriram.png',
    region: 'Provinces',
  },
  {
    name: 'Chachoengsao',
    data: '/images/rsg/logos/Thailand-Chachoengsao.png',
    region: 'Provinces',
  },
  {
    name: 'Chai Nat',
    data: '/images/rsg/logos/Thailand-Chai_Nat.png',
    region: 'Provinces',
  },
  {
    name: 'Chaiyaphum',
    data: '/images/rsg/logos/Thailand-Chaiyaphum.png',
    region: 'Provinces',
  },
  {
    name: 'Chanthaburi',
    data: '/images/rsg/logos/Thailand-Chanthaburi.png',
    region: 'Provinces',
  },
  {
    name: 'Chiang Mai',
    data: '/images/rsg/logos/Thailand-Chiang_Mai.png',
    region: 'Provinces',
  },
  {
    name: 'Chiang Rai',
    data: '/images/rsg/logos/Thailand-Chiang_Rai.png',
    region: 'Provinces',
  },
  {
    name: 'Chonburi',
    data: '/images/rsg/logos/Thailand-Chonburi.png',
    region: 'Provinces',
  },
  {
    name: 'Chumphon',
    data: '/images/rsg/logos/Thailand-Chumphon.png',
    region: 'Provinces',
  },
  {
    name: 'Kalasin',
    data: '/images/rsg/logos/Thailand-Kalasin.png',
    region: 'Provinces',
  },
  {
    name: 'Kamphaeng Phet',
    data: '/images/rsg/logos/Thailand-Kamphaeng_Phet.png',
    region: 'Provinces',
  },
  {
    name: 'Kanchanaburi',
    data: '/images/rsg/logos/Thailand-Kanchanaburi.png',
    region: 'Provinces',
  },
  {
    name: 'Khon Kaen',
    data: '/images/rsg/logos/Thailand-Khon_Kaen.png',
    region: 'Provinces',
  },
  {
    name: 'Krabi',
    data: '/images/rsg/logos/Thailand-Krabi.png',
    region: 'Provinces',
  },
  {
    name: 'Lampang',
    data: '/images/rsg/logos/Thailand-Lampang.png',
    region: 'Provinces',
  },
  {
    name: 'Lamphun',
    data: '/images/rsg/logos/Thailand-Lamphun.png',
    region: 'Provinces',
  },
  {
    name: 'Loei',
    data: '/images/rsg/logos/Thailand-Loei.png',
    region: 'Provinces',
  },
  {
    name: 'Lopburi',
    data: '/images/rsg/logos/Thailand-Lopburi.png',
    region: 'Provinces',
  },
  {
    name: 'Mae Hong Son',
    data: '/images/rsg/logos/Thailand-Mae_Hong_Son.png',
    region: 'Provinces',
  },
  {
    name: 'Maha Sarakham',
    data: '/images/rsg/logos/Thailand-Maha_Sarakham.png',
    region: 'Provinces',
  },
  {
    name: 'Mukdahan',
    data: '/images/rsg/logos/Thailand-Mukdahan.png',
    region: 'Provinces',
  },
  {
    name: 'Nakhon Nayok',
    data: '/images/rsg/logos/Thailand-Nakhon_Nayok.png',
    region: 'Provinces',
  },
  {
    name: 'Nakhon Pathom',
    data: '/images/rsg/logos/Thailand-Nakhon_Pathom.png',
    region: 'Provinces',
  },
  {
    name: 'Nakhon Phanom',
    data: '/images/rsg/logos/Thailand-Nakhon_Phanom.png',
    region: 'Provinces',
  },
  {
    name: 'Nakhon Ratchasima',
    data: '/images/rsg/logos/Thailand-Nakhon_Ratchasima.png',
    region: 'Provinces',
  },
  {
    name: 'Nakhon Sawan',
    data: '/images/rsg/logos/Thailand-Nakhon_Sawan.png',
    region: 'Provinces',
  },
  {
    name: 'Nakhon Si Thammarat',
    data: '/images/rsg/logos/Thailand-Nakhon_Si_Thammarat.png',
    region: 'Provinces',
  },
  {
    name: 'Nan',
    data: '/images/rsg/logos/Thailand-Nan.png',
    region: 'Provinces',
  },
  {
    name: 'Narathiwat',
    data: '/images/rsg/logos/Thailand-Narathiwat.png',
    region: 'Provinces',
  },
  {
    name: 'Nong Bua Lamphu',
    data: '/images/rsg/logos/Thailand-Nong_Bua_Lamphu.png',
    region: 'Provinces',
  },
  {
    name: 'Nong Khai',
    data: '/images/rsg/logos/Thailand-Nong_Khai.png',
    region: 'Provinces',
  },
  {
    name: 'Nonthaburi',
    data: '/images/rsg/logos/Thailand-Nonthaburi.png',
    region: 'Provinces',
  },
  {
    name: 'Pathum Thani',
    data: '/images/rsg/logos/Thailand-Pathum_Thani.png',
    region: 'Provinces',
  },
  {
    name: 'Pattani',
    data: '/images/rsg/logos/Thailand-Pattani.png',
    region: 'Provinces',
  },
  {
    name: 'Phang Nga',
    data: '/images/rsg/logos/Thailand-Phang_Nga.png',
    region: 'Provinces',
  },
  {
    name: 'Phatthalung',
    data: '/images/rsg/logos/Thailand-Phatthalung.png',
    region: 'Provinces',
  },
  {
    name: 'Phayao',
    data: '/images/rsg/logos/Thailand-Phayao.png',
    region: 'Provinces',
  },
  {
    name: 'Phetchabun',
    data: '/images/rsg/logos/Thailand-Phetchabun.png',
    region: 'Provinces',
  },
  {
    name: 'Phetchaburi',
    data: '/images/rsg/logos/Thailand-Phetchaburi.png',
    region: 'Provinces',
  },
  {
    name: 'Phichit',
    data: '/images/rsg/logos/Thailand-Phichit.png',
    region: 'Provinces',
  },
  {
    name: 'Phitsanulok',
    data: '/images/rsg/logos/Thailand-Phitsanulok.png',
    region: 'Provinces',
  },
  {
    name: 'Phra Nakhon Si Ayutthaya',
    data: '/images/rsg/logos/Thailand-Phra_Nakhon_Si_Ayutthaya.png',
    region: 'Provinces',
  },
  {
    name: 'Phrae',
    data: '/images/rsg/logos/Thailand-Phrae.png',
    region: 'Provinces',
  },
  {
    name: 'Phuket',
    data: '/images/rsg/logos/Thailand-Phuket.png',
    region: 'Provinces',
  },
  {
    name: 'Prachinburi',
    data: '/images/rsg/logos/Thailand-Prachinburi.png',
    region: 'Provinces',
  },
  {
    name: 'Prachuap Khiri Khan',
    data: '/images/rsg/logos/Thailand-Prachuap_Khiri_Khan.png',
    region: 'Provinces',
  },
  {
    name: 'Ranong',
    data: '/images/rsg/logos/Thailand-Ranong.png',
    region: 'Provinces',
  },
  {
    name: 'Ratchaburi',
    data: '/images/rsg/logos/Thailand-Ratchaburi.png',
    region: 'Provinces',
  },
  {
    name: 'Rayong',
    data: '/images/rsg/logos/Thailand-Rayong.png',
    region: 'Provinces',
  },
  {
    name: 'Roi Et',
    data: '/images/rsg/logos/Thailand-Roi_Et.png',
    region: 'Provinces',
  },
  {
    name: 'Sa Kaeo',
    data: '/images/rsg/logos/Thailand-Sa_Kaeo.png',
    region: 'Provinces',
  },
  {
    name: 'Sakon Nakhon',
    data: '/images/rsg/logos/Thailand-Sakon_Nakhon.png',
    region: 'Provinces',
  },
  {
    name: 'Samut Prakan',
    data: '/images/rsg/logos/Thailand-Samut_Prakan.png',
    region: 'Provinces',
  },
  {
    name: 'Samut Sakhon',
    data: '/images/rsg/logos/Thailand-Samut_Sakhon.png',
    region: 'Provinces',
  },
  {
    name: 'Samut Songkhram',
    data: '/images/rsg/logos/Thailand-Samut_Songkhram.png',
    region: 'Provinces',
  },
  {
    name: 'Saraburi',
    data: '/images/rsg/logos/Thailand-Saraburi.png',
    region: 'Provinces',
  },
  {
    name: 'Satun',
    data: '/images/rsg/logos/Thailand-Satun.png',
    region: 'Provinces',
  },
  {
    name: 'Sing Buri',
    data: '/images/rsg/logos/Thailand-Sing_Buri.png',
    region: 'Provinces',
  },
  {
    name: 'Sisaket',
    data: '/images/rsg/logos/Thailand-Sisaket.png',
    region: 'Provinces',
  },
  {
    name: 'Songkhla',
    data: '/images/rsg/logos/Thailand-Songkhla.png',
    region: 'Provinces',
  },
  {
    name: 'Sukhothai',
    data: '/images/rsg/logos/Thailand-Sukhothai.png',
    region: 'Provinces',
  },
  {
    name: 'Suphan Buri',
    data: '/images/rsg/logos/Thailand-Suphan_Buri.png',
    region: 'Provinces',
  },
  {
    name: 'Surat Thani',
    data: '/images/rsg/logos/Thailand-Surat_Thani.png',
    region: 'Provinces',
  },
  {
    name: 'Surin',
    data: '/images/rsg/logos/Thailand-Surin.png',
    region: 'Provinces',
  },
  {
    name: 'Tak',
    data: '/images/rsg/logos/Thailand-Tak.png',
    region: 'Provinces',
  },
  {
    name: 'Trang',
    data: '/images/rsg/logos/Thailand-Trang.png',
    region: 'Provinces',
  },
  {
    name: 'Trat',
    data: '/images/rsg/logos/Thailand-Trat.png',
    region: 'Provinces',
  },
  {
    name: 'Ubon Ratchathani',
    data: '/images/rsg/logos/Thailand-Ubon_Ratchathani.png',
    region: 'Provinces',
  },
  {
    name: 'Udon Thani',
    data: '/images/rsg/logos/Thailand-Udon_Thani.png',
    region: 'Provinces',
  },
  {
    name: 'Uthai Thani',
    data: '/images/rsg/logos/Thailand-Uthai_Thani.png',
    region: 'Provinces',
  },
  {
    name: 'Uttaradit',
    data: '/images/rsg/logos/Thailand-Uttaradit.png',
    region: 'Provinces',
  },
  {
    name: 'Yala',
    data: '/images/rsg/logos/Thailand-Yala.png',
    region: 'Provinces',
  },
  {
    name: 'Yasothon',
    data: '/images/rsg/logos/Thailand-Yasothon.png',
    region: 'Provinces',
  },
]

const Indonesia = [
  // 38 provinces
  {
    name: 'Aceh',
    data: '/images/rsg/logos/Indonesia-Aceh.png',
    region: 'Provinces',
  },
  {
    name: 'Bali',
    data: '/images/rsg/logos/Indonesia-Bali.png',
    region: 'Provinces',
  },
  {
    name: 'Bangka Belitung Islands',
    data: '/images/rsg/logos/Indonesia-Bangka_Belitung_Islands.png',
    region: 'Provinces',
  },
  {
    name: 'Banten',
    data: '/images/rsg/logos/Indonesia-Banten.png',
    region: 'Provinces',
  },
  {
    name: 'Bengkulu',
    data: '/images/rsg/logos/Indonesia-Bengkulu.png',
    region: 'Provinces',
  },
  {
    name: 'Central Java',
    data: '/images/rsg/logos/Indonesia-Central_Java.png',
    region: 'Provinces',
  },
  {
    name: 'Central Kalimantan',
    data: '/images/rsg/logos/Indonesia-Central_Kalimantan.png',
    region: 'Provinces',
  },
  {
    name: 'Central Papua',
    data: '/images/rsg/logos/Indonesia-Central_Papua.png',
    region: 'Provinces',
  },
  {
    name: 'Central Sulawesi',
    data: '/images/rsg/logos/Indonesia-Central_Sulawesi.png',
    region: 'Provinces',
  },
  {
    name: 'East Java',
    data: '/images/rsg/logos/Indonesia-East_Java.png',
    region: 'Provinces',
  },
  {
    name: 'East Kalimantan',
    data: '/images/rsg/logos/Indonesia-East_Kalimantan.png',
    region: 'Provinces',
  },
  {
    name: 'East Nusa Tenggara',
    data: '/images/rsg/logos/Indonesia-East_Nusa_Tenggara.png',
    region: 'Provinces',
  },
  {
    name: 'Gorontalo',
    data: '/images/rsg/logos/Indonesia-Gorontalo.png',
    region: 'Provinces',
  },
  {
    name: 'Highland Papua',
    data: '/images/rsg/logos/Indonesia-Highland_Papua.png',
    region: 'Provinces',
  },
  {
    name: 'Jakarta',
    data: '/images/rsg/logos/Indonesia-Jakarta.png',
    region: 'Provinces',
  },
  {
    name: 'Jambi',
    data: '/images/rsg/logos/Indonesia-Jambi.png',
    region: 'Provinces',
  },
  {
    name: 'Lampung',
    data: '/images/rsg/logos/Indonesia-Lampung.png',
    region: 'Provinces',
  },
  {
    name: 'Maluku',
    data: '/images/rsg/logos/Indonesia-Maluku.png',
    region: 'Provinces',
  },
  {
    name: 'North Kalimantan',
    data: '/images/rsg/logos/Indonesia-North_Kalimantan.png',
    region: 'Provinces',
  },
  {
    name: 'North Maluku',
    data: '/images/rsg/logos/Indonesia-North_Maluku.png',
    region: 'Provinces',
  },
  {
    name: 'North Sulawesi',
    data: '/images/rsg/logos/Indonesia-North_Sulawesi.png',
    region: 'Provinces',
  },
  {
    name: 'North Sumatra',
    data: '/images/rsg/logos/Indonesia-North_Sumatra.png',
    region: 'Provinces',
  },
  {
    name: 'Papua',
    data: '/images/rsg/logos/Indonesia-Papua.png',
    region: 'Provinces',
  },
  {
    name: 'Riau',
    data: '/images/rsg/logos/Indonesia-Riau.png',
    region: 'Provinces',
  },
  {
    name: 'Riau Islands',
    data: '/images/rsg/logos/Indonesia-Riau_Islands.png',
    region: 'Provinces',
  },
  {
    name: 'South Kalimantan',
    data: '/images/rsg/logos/Indonesia-South_Kalimantan.png',
    region: 'Provinces',
  },
  {
    name: 'South Papua',
    data: '/images/rsg/logos/Indonesia-South_Papua.png',
    region: 'Provinces',
  },
  {
    name: 'South Sulawesi',
    data: '/images/rsg/logos/Indonesia-South_Sulawesi.png',
    region: 'Provinces',
  },
  {
    name: 'South Sumatra',
    data: '/images/rsg/logos/Indonesia-South_Sumatra.png',
    region: 'Provinces',
  },
  {
    name: 'Southeast Sulawesi',
    data: '/images/rsg/logos/Indonesia-Southeast_Sulawesi.png',
    region: 'Provinces',
  },
  {
    name: 'Southwest Papua',
    data: '/images/rsg/logos/Indonesia-Southwest_Papua.png',
    region: 'Provinces',
  },
  {
    name: 'West Java',
    data: '/images/rsg/logos/Indonesia-West_Java.png',
    region: 'Provinces',
  },
  {
    name: 'West Kalimantan',
    data: '/images/rsg/logos/Indonesia-West_Kalimantan.png',
    region: 'Provinces',
  },
  {
    name: 'West Nusa Tenggara',
    data: '/images/rsg/logos/Indonesia-West_Nusa_Tenggara.png',
    region: 'Provinces',
  },
  {
    name: 'West Papua',
    data: '/images/rsg/logos/Indonesia-West_Papua.png',
    region: 'Provinces',
  },
  {
    name: 'West Sulawesi',
    data: '/images/rsg/logos/Indonesia-West_Sulawesi.png',
    region: 'Provinces',
  },
  {
    name: 'West Sumatra',
    data: '/images/rsg/logos/Indonesia-West_Sumatra.png',
    region: 'Provinces',
  },
  {
    name: 'Yogyakarta',
    data: '/images/rsg/logos/Indonesia-Yogyakarta.png',
    region: 'Provinces',
  },
]

const India = [
  // 28 states + 8 union territories
  {
    name: 'Andhra Pradesh',
    data: '',
    region: 'States',
  },
  {
    name: 'Arunachal Pradesh',
    data: '',
    region: 'States',
  },
  {
    name: 'Assam',
    data: '',
    region: 'States',
  },
  {
    name: 'Bihar',
    data: '',
    region: 'States',
  },
  {
    name: 'Chhattisgarh',
    data: '',
    region: 'States',
  },
  {
    name: 'Goa',
    data: '',
    region: 'States',
  },
  {
    name: 'Gujarat',
    data: '',
    region: 'States',
  },
  {
    name: 'Haryana',
    data: '',
    region: 'States',
  },
  {
    name: 'Himachal Pradesh',
    data: '',
    region: 'States',
  },
  {
    name: 'Jharkhand',
    data: '',
    region: 'States',
  },
  {
    name: 'Karnataka',
    data: '',
    region: 'States',
  },
  {
    name: 'Kerala',
    data: '',
    region: 'States',
  },
  {
    name: 'Madhya Pradesh',
    data: '',
    region: 'States',
  },
  {
    name: 'Maharashtra',
    data: '',
    region: 'States',
  },
  {
    name: 'Manipur',
    data: '',
    region: 'States',
  },
  {
    name: 'Meghalaya',
    data: '',
    region: 'States',
  },
  {
    name: 'Mizoram',
    data: '',
    region: 'States',
  },
  {
    name: 'Nagaland',
    data: '',
    region: 'States',
  },
  {
    name: 'Odisha',
    data: '',
    region: 'States',
  },
  {
    name: 'Punjab',
    data: '',
    region: 'States',
  },
  {
    name: 'Rajasthan',
    data: '',
    region: 'States',
  },
  {
    name: 'Sikkim',
    data: '',
    region: 'States',
  },
  {
    name: 'Tamil Nadu',
    data: '',
    region: 'States',
  },
  {
    name: 'Telangana',
    data: '',
    region: 'States',
  },
  {
    name: 'Tripura',
    data: '',
    region: 'States',
  },
  {
    name: 'Uttar Pradesh',
    data: '',
    region: 'States',
  },
  {
    name: 'Uttarakhand',
    data: '',
    region: 'States',
  },
  {
    name: 'West Bengal',
    data: '',
    region: 'States',
  },
  {
    name: 'Andaman and Nicobar Islands',
    data: '',
    region: 'Union Territories',
  },
  {
    name: 'Chandigarh',
    data: '',
    region: 'Union Territories',
  },
  {
    name: 'Dadra and Nagar Haveli and Daman and Diu',
    data: '',
    region: 'Union Territories',
  },
  {
    name: 'Delhi',
    data: '',
    region: 'Union Territories',
  },
  {
    name: 'Jammu and Kashmir',
    data: '',
    region: 'Union Territories',
  },
  {
    name: 'Ladakh',
    data: '',
    region: 'Union Territories',
  },
  {
    name: 'Lakshadweep',
    data: '',
    region: 'Union Territories',
  },
  {
    name: 'Puducherry',
    data: '',
    region: 'Union Territories',
  },
]

export const CountryList = {
  Luxembourg: {
    name: 'Luxembourg',
    flag: '🇱🇺',
    dataList: Luxembourg,
    regionList: ['Cantons'],
    hasFlag: false,
  },
  New_Zealand: {
    name: 'New Zealand',
    flag: '🇳🇿',
    dataList: New_Zealand,
    regionList: ['Regions', 'Special Territorial Authority'],
    hasFlag: false,
  },
  United_States: {
    name: 'United States',
    flag: '🇺🇸',
    dataList: United_States,
    regionList: ['States'],
    hasFlag: true,
  },
  Malaysia: {
    name: 'Malaysia',
    flag: '🇲🇾',
    dataList: Malaysia,
    regionList: ['States', 'Federal Territories'],
    hasFlag: true,
  },
  United_Kingdom: {
    name: 'United Kingdom',
    flag: '🇬🇧',
    dataList: United_Kingdom,
    regionList: ['Countries'],
    hasFlag: true,
  },
  Switzerland: {
    name: 'Switzerland',
    flag: '🇨🇭',
    dataList: Switzerland,
    regionList: ['Cantons'],
    hasFlag: true,
  },
  Spain: {
    name: 'Spain',
    flag: '🇪🇸',
    dataList: Spain,
    regionList: ['Autonomous Communities', 'Autonomous Cities'],
    hasFlag: true,
  },
  France: {
    name: 'France',
    flag: '🇫🇷',
    dataList: France,
    regionList: [
      'Regions',
      'Overseas Collectivities',
      'Sui Generis Collectivity',
      'Overseas Territory',
      'State Private Property',
    ],
    hasFlag: true,
  },
  Portugal: {
    name: 'Portugal',
    flag: '🇵🇹',
    dataList: Portugal,
    regionList: ['Districts', 'Autonomous Regions'],
    hasFlag: true,
  },
  Australia: {
    name: 'Australia',
    flag: '🇦🇺',
    dataList: Australia,
    regionList: ['States', 'Internal Territories', 'External Territories'],
    hasFlag: true,
  },
  Denmark: {
    name: 'Denmark',
    flag: '🇩🇰',
    dataList: Denmark,
    regionList: ['Regions', 'Autonomous Territories'],
    hasFlag: true,
  },
  Ireland: {
    name: 'Ireland',
    flag: '🇮🇪',
    dataList: Ireland,
    regionList: ['Counties'],
    hasFlag: true,
  },
  Norway: {
    name: 'Norway',
    flag: '🇳🇴',
    dataList: Norway,
    regionList: [
      'Counties',
      'Combined Municipality and County',
      'Integral Overseas Areas',
      'Dependencies',
    ],
    hasFlag: true,
  },
  Italy: {
    name: 'Italy',
    flag: '🇮🇹',
    dataList: Italy,
    regionList: ['Ordinary Regions', 'Autonomous Regions'],
    hasFlag: true,
  },
  Netherlands: {
    name: 'Netherlands',
    flag: '🇳🇱',
    dataList: Netherlands,
    regionList: ['Constituent Countries'],
    hasFlag: true,
  },
  Canada: {
    name: 'Canada',
    flag: '🇨🇦',
    dataList: Canada,
    regionList: ['Provinces', 'Territories'],
    hasFlag: true,
  },
  Germany: {
    name: 'Germany',
    flag: '🇩🇪',
    dataList: Germany,
    regionList: ['States'],
    hasFlag: true,
  },
  Sweden: {
    name: 'Sweden',
    flag: '🇸🇪',
    dataList: Sweden,
    regionList: ['Counties'],
    hasFlag: true,
  },
  Belgium: {
    name: 'Belgium',
    flag: '🇧🇪',
    dataList: Belgium,
    regionList: ['Regions'],
    hasFlag: true,
  },
  Finland: {
    name: 'Finland',
    flag: '🇫🇮',
    dataList: Finland,
    regionList: ['Regions', 'Autonomous Region'],
    hasFlag: true,
  },
  Austria: {
    name: 'Austria',
    flag: '🇦🇹',
    dataList: Austria,
    regionList: ['Federal States'],
    hasFlag: true,
  },
  Singapore: {
    name: 'Singapore',
    flag: '🇸🇬',
    dataList: Singapore,
    regionList: ['Districts'],
    hasFlag: false,
  },
  Japan: {
    name: 'Japan',
    flag: '🇯🇵',
    dataList: Japan,
    regionList: ['Prefectures'],
    hasFlag: true,
  },
  South_Korea: {
    name: 'South Korea',
    flag: '🇰🇷',
    dataList: South_Korea,
    regionList: ['Provinces', 'Cities'],
    hasFlag: true,
  },
  China: {
    name: 'China',
    flag: '🇨🇳',
    dataList: China,
    regionList: [
      'Provinces',
      'Autonomous Regions',
      'Municipalities',
      // 'Special Administrative Regions',
    ],
    hasFlag: false,
  },
  Thailand: {
    name: 'Thailand',
    flag: '🇹🇭',
    dataList: Thailand,
    regionList: ['Provinces', 'Special Administrative Area'],
    hasFlag: true,
  },
  Indonesia: {
    name: 'Indonesia',
    flag: '🇮🇩',
    dataList: Indonesia,
    regionList: ['Provinces'],
    hasFlag: true,
  },
  India: {
    name: 'India',
    flag: '🇮🇳',
    dataList: India,
    regionList: ['States', 'Union Territories'],
    hasFlag: false,
  },
}

//algo
// https://query.wikidata.org
// SELECT ?country ?countryLabel ?subdivision ?subdivisionLabel ?flag WHERE {
//   # Get all countries
//   ?country wdt:P31 wd:Q6256.

//   # Get their subdivisions
//   ?country wdt:P150 ?subdivision.

//   # Get the flag of the subdivision (if available)
//   OPTIONAL { ?subdivision wdt:P41 ?flag. }

//   # Labels in English (adjust to your language preference)
//   SERVICE wikibase:label { bd:serviceParam wikibase:language "en". }
// }

// References:
// https://en.wikipedia.org/wiki/List_of_administrative_divisions_by_country
// https://vexillology.fandom.com/wiki/Norway
// https://encycolorpedia.com/flags
